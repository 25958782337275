import { useEffect, useState } from "react";
import { GiCometSpark } from "react-icons/gi";
import { CiMenuBurger } from "react-icons/ci";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import handleAxiosErrors from "../utils/functions";
import axios from "axios";
import { logoutRoute } from "../utils/routes";
import { useDispatch } from "react-redux";
import { logoutUser } from "../redux/features/auth/authActions";
import { Role } from "../App";
import { MdOutlineScience } from "react-icons/md";

const Header: React.FC = () => {
    const [toggleMobileNavLinks, setToggleMobileNavLinks] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth > 768) {
            setToggleMobileNavLinks(false); 
          }
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    return (
        <header className={`dummy-header`}>
            <nav className='dummy-header-navbar'>
            
                <Link onClick={() => window.scrollTo(0, 0)} to={'/'} id="scrollToTop" className='dummy-header-brand txt-sdw'>C<div className = 'cometColor'><GiCometSpark/></div>met Corner</Link>
                
                <div>
                    <Links show = {toggleMobileNavLinks} setShow={setToggleMobileNavLinks}/>
                </div>
                
                <div className = 'showMobile'>
                    <button onClick={() => setToggleMobileNavLinks(!toggleMobileNavLinks)} className = 'MobileButton'><CiMenuBurger /></button>
                </div>
            </nav>
        </header>
    );
}

interface LinksProps {
    show: boolean
    setShow: (show: boolean) => void;
}

const Links:React.FC<LinksProps> = ({show, setShow}) => {
    const user = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();


    return (
        <ul className={`dummy-header-navLinks ${show? 'display':''}`} onClick={() => setShow(false)}>
            {(  user.role === Role.PREMIUM || 
                user.role === Role.ADMIN) && 
                    <li>
                        <Link   className = {location.pathname.includes('/apps')?'singleLink-active': 'singleLink'}
                                to='/apps' 
                                onClick={() => window.scrollTo(0, 0)}>
                            Apps
                        </Link>
                    </li>}
            {(  user.role === Role.PREMIUM || 
                user.role === Role.ADMIN) && 
                    <li>
                        <Link   to = '/library' 
                                className = {location.pathname.includes('/library')?'singleLink-active': 'singleLink'}
                                onClick={() => window.scrollTo(0, 0)}> 
                            Knjižnica 
                        </Link> 
                    </li>}
            
            {/* {(  user.role === Role.ADMIN) && 
                    <li>
                        <Link   to = '/cms' 
                                className = {location.pathname.includes('/library')?'singleLink-active': 'singleLink'}
                                onClick={() => window.scrollTo(0, 0)}> 
                            CMS 
                        </Link> 
                    </li>} */}

            {(  user.role === Role.PREMIUM || 
                user.role === Role.ADMIN) && 
                    <li>
                        <Link   className = {location.pathname.includes('/experience')?'singleLink-active': 'singleLink'}
                                to='/experience' 
                                onClick={() => window.scrollTo(0, 0)}>
                            Experience
                        </Link>
                    </li>}

            {(  user.role === Role.PREMIUM || 
                user.role === Role.STANDARD || 
                user.role === Role.ADMIN ) && 
                    <li>
                        <Link   className = {location.pathname.includes('/laboratory')?'singleLink-active': 'singleLink'}
                                to='/laboratory' 
                                onClick={() => window.scrollTo(0, 0)}>
                            Laboratorij
                        </Link>
                    </li>}
{/*             <li> 
                <Link   to = '/blog' 
                        className = {location.pathname.includes('/blog')?'singleLink-active': 'singleLink'}
                        onClick={() => window.scrollTo(0, 0)}> 
                    Blog 
                </Link> 
            </li> */}
            <li> 
                <Link   to = '/contact' 
                        className = {location.pathname.includes('/contac')?'singleLink-active': 'singleLink'}
                        onClick={() => window.scrollTo(0, 0)}> 
                    Kontakt 
                </Link> 
            </li>
            {user.username === null?  
                    <li>
                        <Link   to='/login' 
                                onClick={() => window.scrollTo(0, 0)} 
                                className="cta-button singleLink">
                            Prijava
                        </Link>
                    </li>
                :
                <>
                    <li>
                        <Link   to='/profile' 
                                onClick={() => window.scrollTo(0, 0)} 
                                className = {location.pathname.includes('/profile')?'singleLink-active orangeborder': 'singleLink whiteborder'}>
                            <span>{user.username}</span>
                        </Link>
                    </li>

                    <li className = 'cta-button singleLink'>
                        <Link   to = '/logout'
                                onClick={() => window.scrollTo(0, 0)}>
                            Log Out
                        </Link>
                    </li>
                </>
            }
        </ul>
    )
}

export default Header;