import { Link } from "react-router-dom";

const Apps: React.FC = () => {
    return (
        <div className = 'home'>
            <section id = "about" className = 'home-welcome-text'>
                <Link to="gym-tracker" className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                    Fitness<span className = 'orange' > Tracker</span>
                </Link>

                <Link to="diet-tracker" className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                    Diet<span className = 'orange' > Tracker</span>
                </Link>

                <Link to = 'eurojackpot' className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                    Eurojackpot<span className = 'orange'> Predictor</span>
                </Link>
            </section>
        </div>
    )
}

export default Apps;