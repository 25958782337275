import { Link } from "react-router-dom"
import { workoutTypeEnum } from "../fitness.interfaces"

const WorkoutTypeMenu: React.FC = () => {

    
    return (
        <div className = 'home'>
            <section id = "about" className = 'home-welcome-text'>
            {Object.entries(workoutTypeEnum).map(([key, value], index) =>  {
                return (
                    <Link key = {index} to={value} className="txt-sdw hwr-opacity">{key}</Link>
                )
            })}
            </section>
        </div>
    )
}

export default WorkoutTypeMenu;