import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Header from './Components/_header';
import HomePage from './Pages/_home';
import Footer from './Components/_footer';
import Experience from './Pages/_experience';
import TadpoleBackground from './custom/tadpoles';
import Login, { LogOut, NewPassword, Register, ResetPassword } from './Components/_Login';
import React, { ReactNode, useEffect } from 'react';
import { checkSession } from './redux/features/auth/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './redux/store';
import Blog from './Pages/_blog';
import Contact from './Pages/_contact';
import Laboratory from './Pages/_laboratory';
import Library from './Pages/library/_library';
import Apps from './Pages/apps/_apps.main';
import Eurojackpot from './Pages/apps/apps/Eurojackpot/eurojackpot.page';
import WorkoutTypeMenu from './Pages/Fitness/Gym/workout.type.menu';
import ExerciseList from './Pages/Fitness/Gym/exercise.name.list';
import ExerciseDashBoard from './Pages/Fitness/Gym/exercise.log.dashboard';
import ExerciseLogForm from './Pages/Fitness/Gym/exercise.log.form';
import ExerciseForm from './Pages/Fitness/Gym/exercise.name.form';
import DayList from './Pages/Fitness/Diet/month.days.list';
import AiDietitian from './Pages/Fitness/Diet/ai.dietitian';
import DailyMealList from './Pages/Fitness/Diet/daily.meals.list';
import MealForm from './Pages/Fitness/Diet/meal.form';
import JSLibs from './Pages/library/javascript_libraries/js.libraries';
import Books from './Pages/library/books/_books';
import Profile from './Pages/_profile';
import { CMS } from './Pages/CMS/_cms';

export enum Role {
  STANDARD = "standard",
  ADMIN = "admin",
  PREMIUM = "premium"
}

function App() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(checkSession());
  }, []);

  return (
    <Router>
        <TadpoleBackground/>
        <Header />
        <Routes>
          <Route path='/' element= {<HomePage/>}/>
          <Route path='/experience' element= {<Experience/>}/>
          <Route path='/login' element= {<Login/>}/>
          <Route path='/register' element= {<Register/>}/>
          <Route path='/logout' element= {<LogOut/>}/>
          <Route path='/reset-password' element = {<ResetPassword/>}/>
          <Route path='/new-password' element = {<NewPassword/>}/>
{/* 
          <Route path='/blog' element= {<Blog/>}/> */}
          <Route path='/profile' element= {<Profile/>}/>
          <Route path='/contact' element= {<Contact/>}/>
          <Route path='/laboratory' element= {<Laboratory/>}/>

          <Route path='/library' element= {<Library/>}/>
          <Route path='/library/js' element= {<JSLibs/>}/>
          <Route path='/library/books' element= {<Books/>}/>
{/* 
          <Route path='/cms' element= {<CMS/>}/> */}

          <Route path='/apps' element= {<Apps/>}/>
          <Route path='/apps/eurojackpot' element= {<Eurojackpot/>}/>

          <Route path='/apps/gym-tracker' element= {<WorkoutTypeMenu/>}/>
          <Route path="/apps/gym-tracker/:type" element={<ExerciseList/>} />
          <Route path="/apps/gym-tracker/:type/:exercise" element={<ExerciseDashBoard/>} />
          <Route path="/apps/gym-tracker/:type/:exercise/new-log" element={<ExerciseLogForm update = {false} formColor = 'transparent'/>} />
          <Route path="/apps/gym-tracker/:type/:exercise/edit/:id" element={<ExerciseLogForm update = {true} formColor = 'transparent'/>} />
          <Route path="/apps/gym-tracker/:type/new-type" element={<ExerciseForm isUpdate = {false}/>} />

          <Route path="/apps/diet-tracker" element={<DayList/>}/>
          <Route path="/apps/diet-tracker/ai" element={<AiDietitian/>} />
          <Route path="/apps/diet-tracker/by-date/:date" element={<DailyMealList/>} />
          <Route path="/apps/diet-tracker/by-date/:date/new" element={<MealForm update = {false} formColor='transparent'/>} />
          <Route path="/apps/diet-tracker/by-date/:date/edit/:id" element={<MealForm update = {true} formColor='transparent'/>} />

        </Routes>
        <Footer/>
    </Router>
  );
}


interface RoleAuthGuardProps {
  children: ReactNode; 
  requiredRole: Role[];
}

const ProtectedRoute: React.FC<RoleAuthGuardProps> = ({ children, requiredRole }) => {
  const userRole = useSelector((state: RootState) => state.auth.role);
  console.log(userRole);
  const isAuthorized = userRole && requiredRole.includes(userRole);

  return isAuthorized ? <>{children}</> : <Navigate to="/login" replace />;
};

export default App;