import React, { useEffect, useRef, useState } from 'react';
import * as OBC from 'openbim-components';
import { filesRoute, modelsRoute } from '../utils/routes';

const IfcViewer: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadModel = async () => {
      if (!containerRef.current) return;

      const components = new OBC.Components();
      components.scene = new OBC.SimpleScene(components);
      components.renderer = new OBC.SimpleRenderer(components, containerRef.current);
      components.camera = new OBC.SimpleCamera(components);
      components.init();

      const scene = components.scene.get();

      (components as any).camera.controls.setLookAt(10, 10, 10, 0, 0, 0);

      new OBC.SimpleGrid(components);
      let fragments = new OBC.FragmentManager(components);

      async function loadFragments(fragmentZipPath: string) {
        const response = await fetch(fragmentZipPath);
        if (!response.ok) {
          throw new Error('Failed to fetch the file');
        }
        const buffer: any = new Uint8Array(await response.arrayBuffer());
        const model = fragments.load(buffer);
        return model;
      }
      
      try {
        setIsLoading(true);
        const karModel: any = loadFragments(`${modelsRoute}?fileName=small.ifc-global.frag`)
        scene.background=null;
        scene.add(karModel);
      } catch (error) {
        console.error('Failed to load the model:', error);
      }
      finally {
        setIsLoading(false); 
      }

      (components as any).scene.setup();

      return () => {
        components.dispose();
      };
    };
    loadModel();
  }, []);

  return <div className = 'IFCviewer-container whiteborder'>
  {isLoading && (
    <div className = 'IFCviewer-container-3D-viewer'></div>
  )}
  <div ref={containerRef} className = 'IFCviewer-container-3D-viewer ' style = {{aspectRatio: `${window.innerWidth/window.innerHeight}`}}/>
</div>
};

export default IfcViewer;
