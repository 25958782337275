import axios from "axios";
import { useEffect, useState } from "react"
import { eurojackpotPredictionProcessRoute, eurojackpotRoute } from "../../../../utils/routes";
import ReactMarkdown from 'react-markdown';

const EurojackpotPage: React.FC = () => {
  
    const [predictionProcessExplanation, setPredictionProcessExplanation] = useState<string>('');

    useEffect(() => {
        fetchPredictionProcessExplanation();
    }, []);

    const fetchPredictionProcessExplanation = async():Promise<void> => {
        try {
            let response = await axios.get(eurojackpotPredictionProcessRoute);

            if (response.status === 200 || response.status === 201) {
                setPredictionProcessExplanation(response.data);
            }
        }
        catch (error) {
            console.log(error);
            alert(error);
        }
    }

    return (
        <div className = 'home'>
        <section className = 'narrowTitle'>
          
          <div className = 'txt-sdw'><span className = 'orange'>Eurojackpot </span>Predictor
          </div>
        </section>
        <section>
            <div className = 'eurojackpot-container linear-bg'>
                
                <div className = 'eurojackpot-container-eurojackpotSection'>
                    <Eurojackpot numberOfCombinations={10}/>
                </div>
                
                <div className = 'eurojackpot-container-theorySection'>
                    <ReactMarkdown children={predictionProcessExplanation}/>
                </div>
            </div> 
        </section>
        </div>
    )
}
interface EurojackpotProps {
    numberOfCombinations: number;
}
export const Eurojackpot: React.FC<EurojackpotProps> = ({numberOfCombinations}) => {

    const COLUMNS = numberOfCombinations;
    const NUMBERS_PER_COLUMN = 5;
    const arrayOfArrays: string[][] = Array.from({ length: COLUMNS }, () => Array.from({ length: NUMBERS_PER_COLUMN }, () => 'x'));
    const [eurojackpotPredictedNumbersArray, setEurojackpotPredictedNumbersArray] = useState<number[][] | string [][]>(arrayOfArrays);

    useEffect(() => {
        fetchEurojackpotPredictedNumbersArray();
    }, []);

    const fetchEurojackpotPredictedNumbersArray = async():Promise<void> => {
        setEurojackpotPredictedNumbersArray(arrayOfArrays);
        try {
            let response = await axios.get(eurojackpotRoute);

            if (response.status === 200 || response.status === 201) {
                setEurojackpotPredictedNumbersArray(response.data.slice(0, numberOfCombinations));
            }
        }
        catch (error) {
            console.log(error)
            alert(error);
        }
    }
    return (
        <div className = 'eurojackpot' /* style = {{aspectRatio: `${window.innerWidth/window.innerHeight}`}} */>
            <ul className = 'eurojackpot-list'
                onClick = {() => fetchEurojackpotPredictedNumbersArray()}>
                {eurojackpotPredictedNumbersArray.map((numbersArray, index) => (
                <li key={index} className = 'eurojackpot-list-item'>
                    {numbersArray.map((number, numberIndex) => (
                    <span key={numberIndex}>{number}</span>
                    ))}
                </li>
                ))}
            </ul>
        </div>
    )
}

export default EurojackpotPage