import { useState } from "react";

interface PdfProps {
    url:string;
    imageURL: string
}
const PdfViewer:React.FC<PdfProps> = ({ url, imageURL }) => {

    const [togglePdfView, setTogglePdfView] = useState<boolean>(false);
    return (

        <div style={{ aspectRatio: 16/9}}>

        {togglePdfView?
            <iframe
                src={url}
                width="100%"
                height="100%"
                allow="fullscreen"
                >
                <p>Your browser does not support PDFs. <a href={url}>Download the PDF</a>.</p>
            </iframe>
        :
            <img src={imageURL} onClick={() => setTogglePdfView(true)}/>
        }
        
      </div>
    );
  };

export default PdfViewer;
  