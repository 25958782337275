import { FaLink } from "react-icons/fa";
import PdfViewer from "../../../Components/_pdf.viewer";
import { imagesRoute, pdfsRoute } from "../../../utils/routes";

const Books: React.FC = () => {
    return (
        <div className = 'home'>
            <section className = 'narrowTitle'>
                <div className = 'txt-sdw'>
                    Books
                </div>
            </section>
        <div className = 'linear-bg'>
            <section className="content pt80">
                <div className = 'text-section '>
                    <div>
                        <h2>Introduction to Algorithms</h2>
                        <p>
                        Knjiga, ki je trenutn na mojem seznamu, je "Introduction to Algorithms". Ta obsežna knjiga ponuja poglobljeno razumevanje osnovnih in naprednih algoritmov ter podatkovnih struktur, ki so ključni za učinkovito reševanje problemov v številnih tehničnih in znanstvenih disciplinah. Z jasnim pojasnilom konceptov, kot so razvrščanje, iskanje, grafi, in dinamično programiranje, knjiga služi kot nepogrešljivo orodje za razvoj algoritmičnega mišljenja.
                        </p>
                        <a href = {`${pdfsRoute}?fileName=Introduction.to.Algorithms.4th.Leiserson.Stein.Rivest.Cormen.pdf`} target="_blank" rel="noopener noreferrer">
                            <FaLink /> Introduction to Algorithms
                        </a>
                    </div>
                </div>
                <PdfViewer  url={`${pdfsRoute}?fileName=Introduction.to.Algorithms.4th.Leiserson.Stein.Rivest.Cormen.pdf`} 
                            imageURL={`${imagesRoute}?fileName=algorithms.webp`}/>
            </section>

            <section className="content reverse">
                <div className = 'text-section '>
                    <div>
                        <h2>C++ Primer</h2>
                        <p>
Poleg osnov algoritmov berem tudi knjigo "C++ Primer". Z bogatim naborom praktičnih primerov in natančnim pregledom osnovnih ter naprednih značilnosti jezika, ta knjiga ponuja bralcem trdno osnovo za razvoj sofisticiranih C++ aplikacij. Osredotoča se na objektno usmerjeno programiranje, uporabo standardnih knjižnic, razumevanje pomnilniškega modela in večnitno programiranje.                        
                        </p>
                        <a href = {`${pdfsRoute}?fileName=c_primer.pdf`}  target="_blank" rel="noopener noreferrer">
                            <FaLink /> C++ Primer
                        </a>
                    </div>
                </div>
                <PdfViewer  url={`${pdfsRoute}?fileName=c_primer.pdf`} 
                            imageURL={`${imagesRoute}?fileName=c_primer.webp`}/>
            </section>

            <section className="content ">
                <div className = 'text-section '>
                    <div>
                        <h2>Essential TypeScript</h2>
                        <p>
                        Essential TypeScript" je temeljit vodnik po jeziku TypeScript, ki obsega od osnovnih konceptov, kot so tipi in vmesniki, do naprednih tem, vključno z generiki in dekoratorji. Na začetku učenja TypeScripta je ta knjiga služila kot uvod v osnove jezika, zdaj pa, ob ponovnem branju, omogoča boljše razumevanje in uporabo zapletenih funkcij za razvoj robustnih aplikacij.
                        </p>
                        <a href = {`${pdfsRoute}?fileName=essential_typescript.pdf`}   target="_blank" rel="noopener noreferrer">
                            <FaLink /> Essential TypeScript
                        </a>
                    </div>
                </div>
                <PdfViewer  url={`${pdfsRoute}?fileName=essential_typescript.pdf`} 
                            imageURL={`${imagesRoute}?fileName=essential_typescript.jpeg`}/>
            </section>

            <section className="content reverse">
                <div className = 'text-section '>
                    <div>
                        <h2>Osnove Programskega Inženirstva, C</h2>
                        <p>
                        Osnove programskega inženirstva C, avtorja Marjana Jenka je bil moj prvi stik s programiranjem, ko sem ga uporabljal pri predmetu osnove programskega inženirstva. Ta učbenik, ki služi kot uvod v programiranje v jeziku C, pokriva osnovne koncepte, kot so zanke, pogojni stavki if-else in strukture podatkov. Ta knjiga mi je prvič omogočila spoznati in razumeti osnovne programske strukture in logiko jezika C, kar je položilo temelje za moje nadaljnje učenje in razvoj v programiranju.
                        </p>
                        <a href = {`${pdfsRoute}?fileName=Osnove_programskega_inzenirstva_C.pdf`}    target="_blank" rel="noopener noreferrer">
                            <FaLink /> Osnove Programskega Inženirstva, C
                        </a>
                    </div>
                </div>
                <PdfViewer  url={`${pdfsRoute}?fileName=Osnove_programskega_inzenirstva_C.pdf`} 
                            imageURL={`${imagesRoute}?fileName=Osnove_programskega_inzenirstva_C.webp`}/>
            </section>
            </div>
        </div>
    )
}


export default Books;