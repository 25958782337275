import { FaReact } from "react-icons/fa";
import { TbBrandTypescript } from "react-icons/tb";
import { FaNode } from "react-icons/fa";
import { BiLogoPostgresql } from "react-icons/bi";
import { FaSass, FaLink } from "react-icons/fa";
import { SiNestjs } from "react-icons/si";
import { FaPython } from "react-icons/fa";
import { SiLabview } from "react-icons/si";
import { GiCometSpark } from "react-icons/gi";
import { LazyLoadComponent } from "./_home";
import { Eurojackpot } from "./apps/apps/Eurojackpot/eurojackpot.page";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { IoFitness } from "react-icons/io5";
import { SiC } from "react-icons/si";
import { imagesRoute, pdfsRoute } from "../utils/routes";

const Experience: React.FC = () => {
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace("#", "");
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <div className = 'home'>
            <section id = "about" className = 'home-welcome-text'>
                <a href = '#about' className = 'txt-sdw' >
                    O<span className = 'orange'> Meni </span>
                </a>
                <a href = '#skills'className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                    Tech<span className = 'orange' > Skills</span>
                </a>
                <a href = '#projects' className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                    Moji<span className = 'orange'> Projekti</span>
                </a>
                <a href = '#cv' className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                    <span className = 'orange'>CV</span>
                </a>
            </section>
            <div className = 'linear-bg'>
                <section className="content pt80">
                    <div className = 'text-section '>
                        <div>
                            <p>
                            V zadnjih 20 mesecih sem se kot web developer, specializiran za React, Node.js, PostgreSQL in TypeScript, poglobil v razvoj inovativnih spletnih informacijskih rešitev. Moje sposobnosti analitičnega razmišljanja, hitrega učenja in reševanja kompleksnih problemov, pridobljene skozi študij mehatronike, se odlično prepletajo s strastjo do tehnoloških inovacij in razvoja spletnih aplikacij. Sem proaktiven, komunikativen in osredotočen na ustvarjanje kakovostnih rešitev.{/*  Moj cilj je nadaljevati profesionalni razvoj na področju spletnega razvoja, sodelovati z dinamičnimi ekipami in se spopadati z zanimivimi projektnimi izzivi, pri čemer si prizadevam, da s časom in izkušnjami napredujem do vodje ekipe. */}
                            </p>
                        </div>
                    </div>
                    <div className = 'text-section '>
                        <img src={`${imagesRoute}?fileName=CV_finite.jpg`}/>
                    </div>
                </section>

                <section className="content reverse">
                    <div className = 'text-section '>
{/*                         <div>
                            <h2>Študij</h2>
                            <p>
                            
Študiral sem na Fakulteti za strojništvo Univerze v Ljubljani, kjer sem se specializiral v mehatroniko. Moj študij mi je bil izjemno pri srcu, saj sem med njim odkril svojo strast do programiranja. Prvi stik s programiranjem sem imel preko jezikov C in Python, ki sta mi odprla vrata v fascinanten svet tehnologije. Moje zanimanje za delovanje stvari na daljavo me je pripeljalo do usmeritve v spletni razvoj (web development), kjer zdaj aktivno raziskujem in si prizadevam graditi svojo kariero. 
                            </p>
                        </div> */}
                        <h2>Študij</h2>
                        <div>
                            <p>
                            
                            Trenutno zaključujem študij mehatronike na Fakulteti za strojništvo, kjer sem prvič prišel v stik s programiranjem z uporabo jezikov C in Python. V sklopu študijskih obveznosti sem se seznanil z osnovami elektrotehnike, PLC tehnologij, pnevmatike, načrtovanja elektro vezij, uporabe SolidWorks za tehnično risanje in programiranja robotskih rok. Del študija so bile tudi praktične vaje z LabVIEW in Siemensovimi programi za PLC.
                            </p>
                        </div>
                        <div>
                            <p>
Poleg tega sem individualno raziskoval Node.js in TypeScript, kar mi je priskrbelo prve izkušnje. Zanima me delo z jezikom C/C++ in strojno opremo, kjer znanje nadgrajujem s poglobljenim študijem literature, kot sta "C++ Primer" in "Introduction to Algorithms". Cilj mi je usmeriti svojo kariero v tehnično zahtevnejše inženirske aplikacije, vendar sem odprt tudi za priložnosti v spletnem razvoju. Zavedam se pomena kontinuiranega izobraževanja in razširjanja tehničnih veščin v hitro razvijajočem se tehnološkem svetu.
                            </p>
                        </div>
                    </div>
                    <div className = 'text-section'>
                        <img src = 'https://www.fs.uni-lj.si/wp-content/uploads/2024/01/UL_FS_logoVER-RGB_bela.png'/>
                    </div>
                </section>

{/*                 <section className="content">
                    <div className = 'text-section '>
                        <div>
                            <h2>Zakaj <span className = 'orange' >programiranje</span></h2>
                            <p>    
Web programiranje me izjemno privlači zaradi svoje neskončne dinamike, možnosti za stalno izboljševanje in nadgrajevanje znanja. Predstavlja področje, ki zahteva konstantno angažiranost, kreativno razmišljanje in strukturiran pristop k reševanju problemov, kar mi prinaša intelektualno stimulacijo in zadovoljstvo. V fascinantnem svetu tehnologije programiranje omogoča inovacije in ustvarjanje novih rešitev brez velikih začetnih stroškov, kar mi daje možnost, da svoje ideje pretvorim v realnost. Poleg tehničnih veščin pa mi web programiranje omogoča tudi, da v svoje projekte vnesem umetniško žilico in smisel za estetiko, združujem različna področja in s tem izstopam s svojimi prispevki. Ta multidisciplinarni pristop zagotavlja neizčrpen vir izzivov in priložnosti za nenehen razvoj, rast in prispevek k napredku v tehnološkem svetu, kar me neprestano motivira za nadaljnje delo in učenje.
                            </p>
                        </div>
                    </div>
                    <div className = 'text-section '>
                        <img src = {`${imagesRoute}?fileName=coding.jpg`}/>
                    </div>
                </section> */}
            </div>
            <section id = "skills" className = 'home-welcome-text'>
          
            <a href = '#about' className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                O<span className = 'orange'> Meni </span>
            </a>
            <a href = '#skills'className = 'txt-sdw'>
                Tech<span className = 'orange' > Skills</span>
            </a>
            <a href = '#projects' className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                Moji<span className = 'orange'> Projekti</span>
            </a>
            <a href = '#cv' className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                <span className = 'orange'>CV</span>
            </a>
            </section>

            <div className = 'linear-bg'>
                <section id="about" className="content pt80">
                    <div className = 'text-section'>
                        <div>
                            <h2>React.js</h2>
                            <p>
                            V zadnjih dveh letih sem intenzivno uporabljal React.js za razvoj spletnih strani in projektov v podjetju, pri čemer sem izkoristil modularnost in moč React Routerja za enostavno usmerjanje med komponentami. Pri Reactu mi je všeč, kako enostavno lahko upravljam s komponentami in stanji, čeprav sem se moral spopasti z izzivi globalnih stanj, kot so podatki o uporabnikih in prevodi. S tem se zdaj poglabljam v Redux, knjižnico, ki služi globalnemu upravljanju s stanji.
                            </p>
                        </div>
                        <div>
                            <p>
                            Poleg tega, da gradim robustne uporabniške vmesnike, zdaj načrtujem razširitev svojih veščin na področju enotnega in integracijskega testiranja. Razumevanje teh tehnik je nujno, saj želim zagotoviti višjo kakovost in stabilnost mojih aplikacij, kar je ključno za profesionalni razvoj in vzdrževanje zapletenih sistemov.
                            </p>
                        </div>

                    </div>
                    <div className = 'text-section ' style = {{aspectRatio:'16/9'}}>
                        <FaReact size={'100%'} color="rgb(97, 218, 251)"/>
                    </div>
                </section>

                <section id="about" className="content reverse">
                    <div className = 'text-section '>
                        <div>
                            <h2>Typescript</h2>
                            <p>
                            V zadnjih dveh letih sem na vseh svojih projektih uporabljal TypeScript, tako v frontendu z Reactom kot v backendu z Node.js. TypeScript mi je omogočil, da sem bolje sledil tipom in strukturi kode, kar je ključno pri razvoju večjih aplikacij. Njegove funkcionalnosti, kot so generiki in vmesniki, so mi pomagale pisati čistejšo in bolj organizirano kodo. 

                            </p>
                        </div>
                        <div>
                            <p>
                            Čeprav sprva nisem popolnoma razumel prednosti TypeScripta, mi je zdaj jasno, kako dragocen je, ko so moji projekti postali obsežnejši. Njegove zmožnosti, kot so generiki in vmesniki, so mi pomagale pisati čistejšo in bolj organizirano kodo, kar olajša razumevanje in vzdrževanje kompleksnih sistemov.
                            </p>
                        </div>

                    </div>
                    <div className = 'text-section ' style = {{aspectRatio:'16/9'}}>
                        <TbBrandTypescript size={'100%'} color="#3178C6"/>
                    </div>
                </section>

                <section id="about" className="content">
                    
                    <div className = 'text-section '>
                        <div>
                            <h2>Node.Js</h2>
                            <p>
Za backend razvoj v podjetju sem uporabljal Node.js, skupaj z Express.js frameworkom. Node.js mi je omogočil izgradnjo učinkovitih in skalabilnih server-side aplikacij. Z Expressom, ki je minimalističen in prilagodljiv framework, sem lahko hitro postavljal REST API-je, kar je ključno za povezovanje mojih frontend in backend komponent.
                            </p>
                        </div>
                        <div>
                            <p>
                            Express mi je prav tako omogočil enostavno integracijo različnih middleware rešitev, kar je izboljšalo rokovanje z zahtevami in odzivi znotraj aplikacije. S tem sem lahko ustvarjal robustne in zanesljive backende, ki so podpirali kompleksne operacije mojih aplikacij. 
                            </p>
                        </div>
                    </div>
                    <div className = 'text-section ' style = {{aspectRatio:'16/9'}}>
                            <FaNode  size={'100%'} color="#339933"/>
                    </div>
                </section>

                <section id="about" className="content reverse">
                    <div className = 'text-section '>
                        <div>
                            <h2>PostgreSQL</h2>
                            <p>
V zadnjih dveh letih sem za upravljanje podatkov v vseh svojih projektih uporabljal PostgreSQL, relacijsko podatkovno bazo, ki je znana po svoji robustnosti in zanesljivosti. Posebej mi je všeč podpora za JSON, ki mi omogoča, da shranjujem in obdelujem podatke v tem formatu neposredno znotraj baze. To je še posebej koristno pri delu z dinamičnimi podatkovnimi strukturami, ki se pogosto pojavljajo v modernih web aplikacijah.
                            </p>
                        </div>

                        <div>
                            <p>
Uporaba PostgreSQL mi je prinesla dodatno fleksibilnost pri oblikovanju shem in upravljanju podatkov, hkrati pa sem lahko izkoristil močne funkcije SQL-a za kompleksne poizvedbe, kar je bistveno izboljšalo performans in učinkovitost mojih aplikacij.
                            </p>
                        </div>
                    </div>
                    <div className = 'text-section ' style = {{aspectRatio:'16/9'}}>
                        <BiLogoPostgresql   size={'100%'} color="#336791"/>
                    </div>
                </section>

                <section id="about" className="content">
                    <div className = 'text-section '>
                        <div>
                            <h2>Sass</h2>
                            <p>
V zadnjih dveh letih sem na vseh projektih uporabljal SASS (Syntactically Awesome Style Sheets), CSS preprocesor, ki omogoča, da pišem bolj urejeno, modularno in ponovno uporabljivo kodo. SASS prinaša številne prednosti, kot so spremenljivke, mixins, in funkcije, ki so mi pomagale avtomatizirati ponavljajoče se sloge in stilizacije.
                            </p>
                        </div>
                    </div>
                    <div className = 'text-section ' style = {{aspectRatio:'16/9'}}>
                        <FaSass size={'100%'} color="#CC6699"/>
                    </div>
                </section>

                <section id="about" className="content reverse">
                    <div className = 'text-section '>
                        <div>
                            <h2>Nest.js</h2>
                            <p>

Dva meseca nazaj sem začel raziskovati Nest.js, inovativni Node.js framework, ki mi je zaradi svoje modularnosti hitro postal všeč. Odločil sem se, da z Nest.js zgradim backend za to spletno stran, kar mi je omogočilo, da izkoristim njegove prednosti pri gradnji organiziranega in učinkovitega server-side okolja.
                            </p>
                        </div>
                        <div>
                            <p>
Nest.js uporablja pristop, ki temelji na modularnosti in uporabi dekoratorjev, kar omogoča jasno ločevanje poslovnih logik in lažje upravljanje koda. Vključil sem tudi TypeORM, orodje za objektno-relacijsko preslikavo, ki mi omogoča, da delam z bazami podatkov na bolj abstraktni ravni, ne da bi se moral ukvarjati z neposrednim pisanjem SQL ukazov.
                            </p>
                        </div>
                    </div>
                    <div className = 'text-section ' style = {{aspectRatio:'16/9'}}>
                        <SiNestjs size={'100%'} color="#E0234E"/>
                    </div>
                </section>

                <section id="about" className="content">
                    <div className = 'text-section '>
                        <div>
                            <h2>Python</h2>
                            <p>
                            
Med študijem sem en semester preživel učenje Pythona, kjer smo uporabljali Jupyter Notebook za osvajanje osnov programiranja, obdelave podatkov, kot so interpolacija in aproksimacija, ter za grafični prikaz podatkov. V tem času sem se seznanil tudi z osnovnimi knjižnicami, kot so Pandas, ki so ključne za analizo in manipulacijo podatkov. Ta izkušnja mi je dala dobro osnovo za razumevanje programiranja in obdelavo podatkov, kar mi je kasneje pomagalo pri razvoju mojih tehničnih spretnosti.
                            </p>
                        </div>

                    </div>
                    <div className = 'text-section ' style = {{aspectRatio:'16/9'}}>
                        <FaPython size={'100%'} color="#FFD43B"/>
                    </div>
                </section>

                <section id="about" className="content reverse">
                    <div className = 'text-section '>
                        <div>
                            <h2>C</h2>
                            <p>
V času študija mehatronike sem intenzivno uporabljal jezik C. Sprva sem se z njim seznanil v prvem semestru med osnovami programiranja, nato pa sem ga redno uporabljal pri programiranju maket, Arduinov in mikrokrmilnikov. Ta izkušnja mi je omogočila poglobljeno razumevanje, kako programski jezik vpliva na interakcijo s strojno opremo in kako optimizirati delovanje na nizkonivojski ravni. Delo z mikrokrmilniki in razvojne ploščice, kot je Arduino, je bilo še posebej zanimivo, saj sem lahko praktično uporabil teoretično znanje in videl neposredne rezultate svojega programiranja v realnem svetu.
                            </p>
                        </div>
                    </div>
                    <div className = 'text-section ' style = {{aspectRatio:'16/9'}}>
                        <SiC size={'100%'} color="white"/>
                    </div>
                </section>

                <section id="about" className="content">
                    <div className = 'text-section '>
                        <div>
                            <h2>Solidworks</h2>
                            <p>
Čeprav morda ne spada neposredno med programerska znanja, sem na fakulteti spoznal Solidworks in ga uporabljal pri številnih predmetih za 3D modeliranje in dokumentiranje. Ta program me je seznanil z osnovami in naprednimi tehnikami CAD (Computer-Aided Design), kar mi je pomagalo pri natančni vizualizaciji in načrtovanju mehanskih komponent za različne projekte.
                            </p>
                        </div>
                        <div>
                            <p>
SolidWorks še vedno občasno uporabljam za osebne projekte, kadar ne programiram za izdelavo uporabnih predmetov z 3D tiskalnikom, kot so deli za dom ali hobi projekte.
                            </p>
                        </div>
                    </div>
                    <div className='text-section' style={{ aspectRatio: '16/9'}}>
                        <img src={`${imagesRoute}?fileName=solidworks.png`} style={{objectFit:'contain'}}/>
                    </div>
                </section>

                <section id="about" className="content reverse">
                    <div className = 'text-section '>
                        <div>
                            <h2>LabVIEW</h2>
                            <p>

LabVIEW sem spoznal med študijem, kjer smo ga uporabljali pri zajemu laboratorijskih podatkov ter pri nadzoru različnih sistemov. Ta program, specializiran za grafično programiranje, je omogočil intuitivno upravljanje s kompleksnimi merilnimi in kontrolnimi sistemi.
                            </p>
                        </div>
                        <div>
                            <p>
Poleg akademske uporabe sem LabVIEW uporabljal tudi na projektu, kjer smo z njim vodili linearni aktuator v laserskem sistemu. Ta izkušnja mi je dala praktično znanje o možnostih LabVIEW-a pri razvoju in implementaciji specifičnih rešitev za avtomatizacijo in nadzor procesov, kar je ključno za natančno in učinkovito delovanje tehničnih sistemov.
                            </p>
                        </div>
                    </div>
                    <div className = 'text-section ' style = {{aspectRatio:'16/9'}}>
                        <SiLabview size={'100%'} color="#fb0"/>
                    </div>
                </section>

            </div>
            <section id = "projects" className = 'home-welcome-text'>
          
            <a href = '#about' className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                O<span className = 'orange'> Meni </span>
            </a>
            <a href = '#skills'className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                Tech<span className = 'orange' > Skills</span>
            </a>
            <a href = '#projects' className = 'txt-sdw'>
                Moji<span className = 'orange'> Projekti</span>
            </a>
            <a href = '#cv' className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                <span className = 'orange'>CV</span>
            </a>
          </section>
          <div className = 'linear-bg'>
            <section id="about" className="content">
                <div className = 'text-section '>
                    <div>
                        <h2>IRGO <span className = 'orange'>Portal</span></h2>
                        <p>
                        Samostojno sem zasnoval in razvil informacijski spletni portal, ki služi kot centralno mesto za shranjevanje, upravljanje in analizo podatkov, kar omogoča vsem zaposlenim dostop in urejanje, izboljšuje iskanje po bazi ter podpira celostno analitično obdelavo. Portal vključuje sekcijo za monitoring in meritve, ki notranjim ekipam omogoča obdelavo terenskih meritev in njihovo grafično predstavitev, s čimer zmanjšujemo odvisnost od drage programske opreme in omogočamo deljenje podatkov z zunanjimi izvajalci. Prav tako sem razvil funkcionalnost za prikaz virtualnih 3D sprehodov projektov in avtomatiziral proces poročanja delovnih ur, kar povečuje učinkovitost in znižuje stroške. Za zagotavljanje nemotenega delovanja spletnega portala sem vzpostavil robustno strežniško infrastrukturo in implementiral sistem za globalno ter lokalno upravljanje z uporabniki, kar omogoča prilagojeno dodeljevanje dostopov in pravic glede na posamezne vloge uporabnikov.
                        </p>
                    </div>
                </div>
                <div className='text-section' style={{ aspectRatio: '16/9', padding: '2rem'}}>
                    <img  src="https://images.squarespace-cdn.com/content/v1/5dbac81daa74663c10257737/1586106109302-0ATSTY1PEULLQ760K2EA/Irgo_logo.png?format=1500w" style={{objectFit:'contain'}}/>
                </div>
            </section>

            <section id="about" className="content reverse">
                <div className = 'text-section '>
                    <div>
                        <h2>cometCorner<span className = 'orange'>.com</span></h2>
                        <p>
                        Moja spletna stran služi kot dinamična platforma, ki združuje osebno predstavitev z naborom praktičnih virov. Zgrajena v React.js in TypeScript za front-end ter Nest.js za back-end, s podatkovno bazo PostgreSQL in stilizacijo s SCSS, ta stran predstavlja srce mojih digitalnih prizadevanj. Funkcionira kot centralizirano mesto za shranjevanje CV-ja in profesionalnih dokumentov, omogoča mi predstavitev mojih privatnih projektov in deluje kot laboratorij za eksperimentiranje z novimi idejami in tehnologijami. Obenem stran vključuje repozitorij mojih programerskih zapiskov in knjižnic, ki služijo kot osebna baza znanja in učnih virov.
                        </p>
                    </div>
                </div>
                <div className='text-section' style = {{aspectRatio:'16/9'}}>
                    <GiCometSpark size={'100%'} color="orange"/>
                </div>
            </section>

            <section id="about" className="content">
                <div className = 'text-section '>
                    <div>
                        <h2>Napovednik<span className = 'orange'> Eurojackpot</span></h2>
                        <p>
                        Koncept napovedovanja loterijskih številk s strategijo, ki uravnoteži izbor med visokimi in nizkimi ter med sodimi in lihimi številkami, da pokrije čim več možnosti. Ta pristop izhaja iz verjetnosti in teorije velikih števil in predpostavlja, da dolgoročno pride do uravnotežene porazdelitve teh kategorij številk.  Čeprav je vsak loterijski žreb sam po sebi nepredvidljiv, me analitični pristop do žreba izredno zabava.
                        </p>
                    </div>
                    <div>
                        <p>
                        Pohitite z vašimi ponudbami za sodelovanje, ker bom kmalu preverjal le še cene tropskih otokov.
                        </p>
                    </div>{/* 
                    <div>
                        <Link to= '/home#about'>
                            <FaLink /> BLOG: Kako sem skoraj zadel Eurojackpot 
                        </Link>
                    </div> */}
                </div>
                <div className='text-section whiteborder bx-shdw hvr-scl'>
                    <LazyLoadComponent>
                        <Eurojackpot numberOfCombinations={5}/>
                    </LazyLoadComponent>
                </div>
            </section>
            <section id="about" className="content reverse">
                <div className = 'text-section '>
                    <div>
                        <h2>Gym<span className = 'orange'>&</span>Diet<span className = 'orange'> Tracker</span></h2>
                        <p>
V svojem nenehnem prizadevanju za izboljšanje telesne pripravljenosti in zdravja sem razvil dve pomembni orodj: gym tracker in diet tracker. Gym tracker mi omogoča natančno spremljanje napredka pri vsaki posamezni vadbi v fitnesu, vključno z grafičnim prikazom mojih dosežkov, kar mi daje jasen pregled nad mojimi izboljšavami in področji, kjer potrebujem dodatno delo. Da pa bi telesni vadbi dodal še pravilno prehrano, sem si ustvaril tudi diet tracker. Ker se pogosto znajdem v dilemi, koliko sem dejansko pojedel ali če sem zaužil dovolj hranilnih snovi, mi diet tracker pomaga slediti kaloričnemu vnosu in zagotoviti, da moja prehrana podpira moje fitnes cilje. Skupaj mi ta dva orodja pomagata vzdrževati uravnotežen pristop k zdravju in dobremu počutju, hkrati pa mi dajeta motivacijo in vpogled, ki ga potrebujem za nadaljnji napredek.
                        </p>
                    </div>
                    <div>
                        <Link to= '/apps' onClick={() => window.scrollTo(0, 0)}>
                            <FaLink /> Apps
                        </Link>
                    </div>
                </div>
                <div className='text-section' style = {{aspectRatio:'16/9'}}>
                    <IoFitness size={'100%'} color="red"/>
                </div>
            </section>
          </div>
          <section id = "cv" className = 'home-welcome-text'>
                <a href = '#about'className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                    O<span className = 'orange'> Meni </span>
                </a>
                <a href = '#skills'className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                    Tech<span className = 'orange' > Skills</span>
                </a>
                <a href = '#projects' className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                    Moji<span className = 'orange'> Projekti</span>
                </a>
                <a href = '#cv'  className = 'txt-sdw' >
                    <span className = 'orange'>CV</span>
                </a>
            </section>
            <div className = 'linear-bg'>
                <section className="content" style = {{aspectRatio: 1/1.414}}>
                    <iframe
                        src={`${pdfsRoute}?fileName=CV_JERNEJ_PENKO_ENG_SLO.pdf`} 
                        width="100%"
                        allow="fullscreen"
                        >
                        <p>Your browser does not support PDFs. <a href={`${pdfsRoute}?fileName=CV_JERNEJ_PENKO_ENG_SLO.pdf`} >Download the PDF</a>.</p>
                    </iframe>
                </section>
            </div>
        </div>
    )
}

export default Experience;