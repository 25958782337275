import { Link, useNavigate, useParams } from "react-router-dom"
import { workoutTypeInterface } from "../fitness.interfaces";
import { useEffect, useState } from "react"
import axios from "axios";
import { workoutTypeRoute } from "../../../utils/routes";


const ExerciseList: React.FC = () => {

    const [exerciseListData, setExerciseListData] = useState<workoutTypeInterface[]>([]);
    const { type } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchExerciseList();
    }, []);

    const fetchExerciseList = async(): Promise<void> => {
        try {
            let response = await axios.get(`${workoutTypeRoute}/${type}`);

            if (response.status === 200 || response.status === 201) {
                setExerciseListData(response.data);
                console.log(response.data);
            }
        }
        catch (error) {
            navigate(-1);
        }
    }
    return (
        <div className = 'home'>
            <section id = "about" className = 'home-welcome-text'>
                <Link to="new-type" className="new-button">+</Link>
                {exerciseListData.map((exercise, key) => {
                    return (
                        <Link key = {key} to={`${exercise.name}`} className="navigation-button noUnderline">{exercise.name}</Link>
                    )
                })}
            </section>
        </div>
    )
}

export default ExerciseList