import axios from "axios";
import { useEffect, useState } from "react"
import { dietRoute } from "../../../utils/routes";
import ReactMarkdown from 'react-markdown';
import { HiRefresh } from "react-icons/hi";


const AiDietitian: React.FC = () => {

    const [nutritionData, setNutritionData] = useState<{[key: string]: number}>({});
    const [dietReportText, setDietReportText] = useState<string>('');

    useEffect(()=> {
        GET_savedAiNutritionReport()
    }, [])

    const setNutritionDataAndText = (TextToParse: string): void=> {
        const {extractedNutritionData, extractedDietReport} = splitStringIntoDataAndReport(TextToParse);
        setNutritionData(extractedNutritionData)
        setDietReportText(extractedDietReport)
    }

    const GET_savedAiNutritionReport = async(): Promise<void> => {
        try {
            let response = await axios.get(`${dietRoute}/ai/dietitian/log`);

            if (response.status === 200 || response.status === 201) {
                setNutritionDataAndText(response.data);
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    const GET_newAiNutritionReport = async(): Promise<void> => {

        setDietReportText('');
        setNutritionData({})
        let response = await axios.get(`${dietRoute}/ai/dietitian`)

        if (response.status === 200 || response.status === 201) {
            setNutritionDataAndText(response.data);
            
        }
    }

    function splitStringIntoDataAndReport(stringToExtract: string): { extractedNutritionData: any; extractedDietReport: string } {
        const jsonPattern = /\{(.*?)\}/s;
        const jsonMatch = stringToExtract.match(jsonPattern);
      
        if (jsonMatch) {

          const startOfJsonInText = stringToExtract.indexOf(jsonMatch[0]);
          const endOfJsonInText = startOfJsonInText + jsonMatch[0].length;
      
          const textBeforeJsonData = stringToExtract.substring(0, startOfJsonInText);
          const textAfterJsonData = stringToExtract.substring(endOfJsonInText);
      
          let foundJsonInText;
          try {
            foundJsonInText = JSON.parse(jsonMatch[0]); 
          } catch (error) {
            foundJsonInText = {}
            return {extractedNutritionData: foundJsonInText, extractedDietReport: 'error'};
          }
      
          return { extractedNutritionData: foundJsonInText, extractedDietReport: textBeforeJsonData + textAfterJsonData };
        } else {
          return { extractedNutritionData: {}, extractedDietReport: stringToExtract };
        }
      }
      
    
    
    return (
        <div className = 'ai-dietitian-container linear-bg'>
            <div className = 'daylist-title'>
                <div className = 'title'>
                    Dietitian AI
                </div>
            </div>

            <div className = 'ai-container'>
                <Typewriter text={dietReportText} json = {nutritionData}/>
            </div>

            <button className = 'ai-button' onClick={GET_newAiNutritionReport}>
                <HiRefresh />
            </button>
        </div>
    )
}
interface TypewriterProps {
    text: string;
    json?: any
  }
  
export const Typewriter: React.FC<TypewriterProps> = ({ text, json }) => {
    const [displayedText, setDisplayedText] = useState<string>('');
  
    useEffect(() => {
      let indexOfCurrentCharacter = 0;
      const intervalId = setInterval(() => {
        setDisplayedText((prev) => prev + text.charAt(indexOfCurrentCharacter));
        indexOfCurrentCharacter++;
        if (indexOfCurrentCharacter === text.length) clearInterval(intervalId);
      }, 50);
  
      return () => clearInterval(intervalId);
    }, [text]);
  
    return <div className = 'type-writer'>
                <pre>
                    <div className = 'listRemoveMP'>
                        {json &&
                            <ul>
                                {Object.entries(json)
                                    .sort((a: any, b: any) => b[1] - a[1])
                                    .map(([key, value], index) => {
                                    return (
                                        <li className = 'listItem p5' key = {index}>
                                            <h3>{key}</h3>
                                            <p>{value as string}</p>
                                        </li>
                                    )
                                })}
                            </ul>}
                    </div>
                    <ReactMarkdown children={displayedText}/>
                </pre>
            </div>;
  };
  
export default AiDietitian