import { useEffect, useState } from "react"
import { workoutTypeInterface, workoutTypeEnum } from "../fitness.interfaces";
import axios from "axios";
import { workoutTypeRoute } from "../../../utils/routes";
import { useNavigate, useParams } from "react-router-dom";

interface ExerciseFormProps {
    isUpdate: boolean;
    formColor?: string;
}
const ExerciseForm: React.FC<ExerciseFormProps> = ({isUpdate, formColor = 'blue'}) => {

    const { type } = useParams();

    let initWorkoutType: workoutTypeInterface = {
        name: '',
        type: workoutTypeEnum.Legs
    }

    const [isExerciseFormOpen, setIsExerciseFormOpen] = useState<boolean>(false);
    const [axiosMessage, setAxiosMessage] = useState<string>('');
    const [workoutTypeData, setWorkoutTypeData] = useState<workoutTypeInterface>(initWorkoutType)

    const navigate = useNavigate();

    useEffect(() => {
        setIsExerciseFormOpen(true);
        setWorkoutTypeData((prev) => ({
            ...prev,
            type: type as workoutTypeEnum
        }))
    }, []);
    useEffect(() => {
        let timerId: number;
        if (!isExerciseFormOpen) {
            timerId = setTimeout(() => navigate(-1), 300) as unknown as number;
        }   
        
        return () => clearTimeout(timerId);
        }, [isExerciseFormOpen]);

    const handleSubmit = async(e: React.FormEvent): Promise<void> => {
        e.preventDefault();
        try {
          let response;
            if ( isUpdate ) {
              response = await axios.put(`${workoutTypeRoute}/${workoutTypeData.id}`, workoutTypeData);
            }
            else {
              response = await axios.post(workoutTypeRoute, workoutTypeData);
            }
            if (response.status === 201 || response.status === 200) {
                setWorkoutTypeData(initWorkoutType);
                
                setTimeout(() => {
                    setIsExerciseFormOpen(false);
                  }, 600);
            }
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response) 
                    setAxiosMessage(e.response.data.message.join('. ') + '.');
            }
        }
      };

    const handleDelete = async (): Promise<void> => {
        if (!workoutTypeData.id) return;
      
        try {
          const response = await axios.delete(`${workoutTypeRoute}/${workoutTypeData.id}`);
          if (response.status === 200) {
    
            setTimeout(() => {
                setIsExerciseFormOpen(false);
              }, 600);
          }
        } catch (e) {
          console.error('Failed to delete the event', e);
          if (axios.isAxiosError(e)) {
            if (e.response) 
                setAxiosMessage(e.response.data.message);
            }
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {
        const { name, value } = e.target;
        setWorkoutTypeData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div className = {`${isExerciseFormOpen ? 'dropdown-container open center'  : 'dropdown-container center'} ${formColor}`}>
            <form onSubmit={handleSubmit} className="form">

                <div className = 'title'>
                    {isUpdate? `Edit: ${workoutTypeData.name}` : `Create ${type?.toUpperCase()} Exercise`}
                </div>

                {axiosMessage && <div className = 'alert'>
                    {axiosMessage}
                </div>}

                {isUpdate && 
                    <select name="type" value={workoutTypeData.type} onChange={handleChange} required className="form-select">
                        {Object.entries(workoutTypeEnum).map(([key, value]) => {
                            return (
                                <option value = {value} key = {key}> {value} </option>
                            )
                        })}
                    </select>
                }
                <input
                    type = 'text'
                    name="name"
                    value={workoutTypeData.name}
                    onChange={handleChange}
                    placeholder="Name Of The Exercise"
                    required
                />

                <button type="submit" className="global-button">{isUpdate? 'Update':'Submit'}</button>

                {isUpdate &&
                    <button type="button" 
                            className="global-button delete" 
                            onClick={handleDelete}
                            disabled = {!isUpdate}>
                            Delete
                    </button>
                }
            </form>
        </div>
    )
}

export default ExerciseForm;