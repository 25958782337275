import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { dietRoute } from '../../../utils/routes';
import { mealInterface } from "../fitness.interfaces";
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate, toLocalTime } from '../../../utils/functions';

const DailyMealList: React.FC = () => {

    const [meals, setMeals] = useState<mealInterface[]>([]);
    const [dailyCaloriesSum, setDailyCaloriesSum] = useState<number>(0);
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

    const navigate = useNavigate();
    const { date } = useParams();

    const fetchMeals = async (): Promise<void> => {

        try {
            const response = await axios.get(`${dietRoute}/by-date`, {
                params: {
                    date: date,
                },
            });
            setMeals(response.data);
            calculateDailyCaloriesSum(response.data);
        } catch (error) {
            console.error('Error fetching meals:', error);
        }
    };

    const calculateDailyCaloriesSum = (meals: mealInterface[]): void => {
      let caloriesSum = meals.reduce((sum, currentMeal) => sum + currentMeal.calories, 0);
      setDailyCaloriesSum(caloriesSum);
    }


    useEffect(() => {

        if (date) {
            fetchMeals();
        }
        else {
            navigate('/diet/')
        }
        
    }, [date]);

    const toggleExpand = (index: number): void => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const handleNewMealClick = (): void => {
      navigate(`new`)
    }
    
    return (
      <div className = 'linear-bg daylist-container' style = {{height:'100vh'}}>
        <div className = 'daylist-title'>
          <div className = 'title'>{formatDate(date as string)}</div>
          <div className = 'calories-sum'>{dailyCaloriesSum} kcal</div>
        </div>
        
        
        <ul className='listRemoveMP'>
          {meals.length > 0 ? (
            meals.map((meal, index) => (
              <React.Fragment key={index}>
                <li className={`listItem font1em p5 ${expandedIndex === index ? 'active' : ''}`} onClick={() => toggleExpand(index)}>
                  <h3>{meal.name}</h3>
                  <p>{meal.food}</p>
                  <p>cca.{meal.calories} kcal</p>
                  <p>{toLocalTime(meal.date) as string}</p>
                </li>
                  <ExpandedDetails
                    content={meal}
                    open={expandedIndex === index}
                    navigate={navigate}
                    date={date? date : ''}
                    />
              </React.Fragment>
            ))
          ) : (
            <p>
              No Records Found For This Date
            </p>
          )}
        </ul>
        <button onClick={handleNewMealClick} className="new-button">add</button>
      </div>
    );
  };



interface ExpandedDetailsProps {
  content: mealInterface,
  open: boolean,
  navigate: (date: string) => void,
  date: string
}

const ExpandedDetails: React.FC<ExpandedDetailsProps> = ({ content, open, navigate, date }) => {
    
    const items = content.amount.split('-').filter(Boolean); 
    const midPoint = Math.ceil(items.length / 2); 
  
    const firstColumnItems= items.slice(0, midPoint);
    const secondColumnItems = items.slice(midPoint);


    const toggleUpdateMeal = (id: number): void => {
      navigate(`edit/${id}`)
    }


    return (
      <div className={`expanded-details ${open ? 'expanded' : '' }` }>
        <div className="column">
          {firstColumnItems.map((item: string, index: number) => (
            <p key={index}>- {item.trim()}</p>
          ))}
        </div>
        <div className="column">
          {secondColumnItems.map((item: string , index: number) => (
            <p key={index}>- {item.trim()}</p>
          ))}
        </div>
        <div className = 'buttons'>
          <button onClick={() => toggleUpdateMeal(content.id as number)}>
              Update
          </button>
        </div>
      </div>
    );
  };
  
export default DailyMealList;