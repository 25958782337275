import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from '../../../App';

interface AuthState {
  isAuthenticated: boolean;
  isGuest: boolean;
  role: Role | null;
  username: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isGuest: true,  // Assume users are guests by default
  role: null,
  username: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<{ role: Role, username: string }>) => {
      state.isAuthenticated = true;
      state.isGuest = false;
      state.username = action.payload.username;
      state.role = action.payload.role;
    },
    setGuest: (state) => {
      state.isAuthenticated = false;
      state.isGuest = true;
      state.role = null;
      state.username = null;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.isGuest = true;
      state.username = null;
      state.role = null;
    }
  },
});

export const { setCredentials, setGuest, logout } = authSlice.actions;
export default authSlice.reducer;
