

const Laboratory: React.FC = () => {
    return (
        <div className = 'home'>
            <section id = "home" className = 'home-welcome-text'>
                <div className = 'txt-sdw'>
                    <span className = 'orange'>Laboratorij</span>
                    <h2>Prostor za raziskovanje, učenje in ustvarjanje.</h2>
                </div>
            </section>
        </div>
    )
};

export default Laboratory;