import { Link } from "react-router-dom";


const Library: React.FC = () => {
    return (
        <div className = 'home'>
            <section id = "home" className = 'home-welcome-text'>
                {/* <div className = 'txt-sdw'>
                    <span className = 'orange'>Knjižnica</span>
                    <h2>Knjige, dokumenti in zapiski na enem mestu.</h2>
                </div> */}
                <Link to="books" className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                    <span className = 'orange' >Knjige</span>
                </Link>

                <Link to="js" className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                    Javascript<span className = 'orange' > Knjižnice</span>
                </Link>
{/* 
                <Link to = 'eurojackpot' className = 'txt-sdw hwr-opacity' style = {{fontSize:'clamp(2rem, 10vw, 5rem)'}}>
                    youtube<span className = 'orange'> Knjižnica</span>
                </Link> */}
            </section>
        </div>
    )
};

export default Library;