import { useEffect, useState } from "react"
import axios from "axios"
import { useNavigate, useParams } from "react-router-dom"
import { getFormattedDate } from "../../../utils/functions"
import { WorkoutInfo } from "../fitness.interfaces";
import { exerciseLogRoute } from "../../../utils/routes"

interface ExerciseLogFormProps {
    update: boolean
    formColor?: string
}
const ExerciseLogForm: React.FC<ExerciseLogFormProps> = ({update, formColor}) => {

    const { exercise, id } = useParams();
    const navigate = useNavigate();

    const initExerciseLog: WorkoutInfo = {
        oneRM: 0,
        reps: 0,
        weight: 0,
        date: getFormattedDate(),
        name: exercise as string
    }
    
    const [showExerciseLogForm, setShowExerciseLogForm] = useState<boolean>(false);
    const [exerciseLogData, setExerciseLogData] = useState<WorkoutInfo>(initExerciseLog);
    
    useEffect(() => {
        const newOneRM = calculateOneRM(exerciseLogData.weight, exerciseLogData.reps);

        setExerciseLogData((prevData) => ({
          ...prevData,
          oneRM: newOneRM,
        }));
      }, [exerciseLogData.weight, exerciseLogData.reps]);

    useEffect(() => {
        setShowExerciseLogForm(true);

        if (update) {
            fetchSelectedExerciseLogData();
        }
        else {
            fetchLatestExerciseLogData();
        }
    }, []);

    useEffect(() => {
        let timerId: number;
        if (!showExerciseLogForm) {
            timerId = setTimeout(() => navigate(-1), 300) as unknown as number;
        }   
        
        return () => clearTimeout(timerId);
        }, [showExerciseLogForm]);


    const fetchLatestExerciseLogData = async(): Promise<void> => {
        try {
            let response = await axios.get(`${exerciseLogRoute}/${exercise}/latest`)

            if (response.status === 200 || response.status === 201) {
                if (response.data) {
                    
                let exerciseLogPreset: WorkoutInfo = {
                    ...response.data,
                    date: getFormattedDate()
                }
                setExerciseLogData(exerciseLogPreset)
                }
            }
        }
        catch(error) {
            console.log(error)
        }
    }

    const fetchSelectedExerciseLogData = async(): Promise<void> => {
        try {
            let response = await axios.get(`${exerciseLogRoute}/exercise/${id}`);
            if (response.status === 201 || response.status === 200) {

                let formatDateOfObject = response.data;
                formatDateOfObject.date = formatDateOfObject.date.split('T')[0]

                setExerciseLogData(formatDateOfObject);
                console.log(formatDateOfObject)
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const calculateOneRM = (weight: number, reps: number): number => {
        return Math.round(weight * (1 + reps / 30));
    };

    const handleSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (update) {
            await UPDATE_exerciseLog();
        }
        else {
            await submitExerciseLog();
        }
        
    };

    const deleteExerciseLog = async(): Promise<void> => {
        try {
            let response = await axios.delete(`${exerciseLogRoute}/${exerciseLogData.id}`);

            if (response.status === 200) {
    
                setTimeout(() => {
                    setShowExerciseLogForm(false);
                  }, 600);
              }
        }
        catch(error) {
            console.log(error);
        }
    }
    
    const submitExerciseLog = async(): Promise<void> => {
        try {

            let response = await axios.post(exerciseLogRoute, exerciseLogData);

            if (response.status === 201 || response.status === 200) {
                setExerciseLogData(initExerciseLog);
                
                setTimeout(() => {
                    setShowExerciseLogForm(false);
                }, 600);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const UPDATE_exerciseLog = async(): Promise<void> => {
        try {
            let response = await axios.put(`${exerciseLogRoute}/${exerciseLogData.id}`, exerciseLogData)

            if (response.status === 201 || response.status === 200) {
                setExerciseLogData(initExerciseLog);
                
                setTimeout(() => {
                    setShowExerciseLogForm(false);
                }, 600);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    return (
        <div className = {`${showExerciseLogForm ? 'dropdown-container open center'  : 'dropdown-container center'} ${formColor}`}>
            <form onSubmit={handleSubmit} className = 'form'>
                <div className = 'title' >
                    {exerciseLogData.name}
                </div>
                <div>
                    <label htmlFor="oneRM">One RM [kg]:</label>
                    <input
                        id="oneRM"
                        type="number"
                        value={exerciseLogData.oneRM}
                        onChange={(e) => setExerciseLogData({ ...exerciseLogData, oneRM: parseInt(e.target.value, 10) })}
                    />
                </div>

                <div>
                    <label htmlFor="weight">Weight: [kg]</label>
                    <input
                        id="weight"
                        type="number"
                        value={exerciseLogData.weight}
                        onChange={(e) => setExerciseLogData({ ...exerciseLogData, weight: parseInt(e.target.value, 10) })}
                    />
                </div>

                <div>
                    <label htmlFor="reps">Reps:</label>
                    <input
                        id="reps"
                        type="number"
                        value={exerciseLogData.reps}
                        onChange={(e) => setExerciseLogData({ ...exerciseLogData, reps: parseInt(e.target.value, 10) })}
                    />
                </div>

                <div>
                    <label htmlFor="date">Date:</label>
                    <input
                        id="date"
                        max={new Date().toISOString().split("T")[0]}
                        type="date"
                        value={exerciseLogData.date}
                        onChange={(e) => setExerciseLogData({ ...exerciseLogData, date: e.target.value })}
                    />
                </div>
                <button className = 'global-button' type="submit">{update?'Save': 'Submit'}</button>
                {update &&
                    <button type="button" 
                            className="global-button delete" 
                            onClick={deleteExerciseLog}
                            disabled = {!update}>
                            Delete
                    </button>
                }
            </form>
        </div>
    )
}
export default ExerciseLogForm