import { AppDispatch } from '../../store';
import axiosInstance from './axiosConfig';
import { setCredentials,  logout } from './authSlice';
import { checkSessionRoute, logoutRoute } from '../../../utils/routes';



export const checkSession = () => async (dispatch: AppDispatch) => {
  
  try {
    const response = await axiosInstance.post(checkSessionRoute);
    const { role, username, message } = response.data;
    if ( response.status == 200 && !message) {
      dispatch(setCredentials({ role, username }));
    } else {
      throw new Error('Session invalid');
    }
  } catch (error) {
    console.error('Session check failed:', error);
    dispatch(logout());
  }
};

export const logoutUser = () => async (dispatch: AppDispatch) => {
  try {
    await axiosInstance.delete(logoutRoute);
    dispatch(logout());
  } catch (error) {
    console.error('Logout failed:', error);
  }
};
