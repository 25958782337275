
export interface mealInterface {
    name: mealType;
    food: string;
    amount: string;
    date: string;
    calories: number;
    id?: number;
    
}
export interface workoutTypeInterface {
    name: string;
    type: workoutTypeEnum;
    id?: number;
};
export enum workoutTypeEnum {
    Push = 'push',
    Pull = 'pull',
    Legs = 'legs',
    Other = 'other'
  }
export enum mealType {
    Zajtrk = 'zajtrk',
    Malica1 = 'malica 1',
    Kosilo = 'Kosilo',
    Malica2 = 'malica 2',
    Vecerja = 'vecerja',
    PoVecerek = 'po vecerek',
    Prigrizek = 'prigrizek'
}
export type WorkoutInfo = {
    oneRM: number;
    reps: number;
    weight: number;
    date: string;
    name: string;
    id?: number
};