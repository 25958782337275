import { useEffect, useState } from "react"
import { dietRoute } from "../../../utils/routes";
import axios from "axios";
import { mealInterface, mealType } from "../fitness.interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate, toLocalTime } from "../../../utils/functions";

interface MealFormProps  {
    update:boolean
    formColor?: string;
}

const MealForm: React.FC<MealFormProps> = ({update, formColor = 'blue'}) => {

    const { date, id } = useParams();
    const navigate = useNavigate();

    let initMealData: mealInterface = {
        name: mealType.Zajtrk,
        food: '',
        amount: '',
        date: '',
        calories: 0
    }
    const [showMealForm, setShowMealForm] = useState<boolean>(false);
    const [mealData, setMealData] = useState<mealInterface>(initMealData);
    const [axiosResponseMessage, setAxiosResponseMessage] = useState<string>('');
    const [selectedTimeOfMeal, setSelectedTimeOfMeal] = useState<string>('00:00');

    useEffect(() => {
        setShowMealForm(true);

        if (update === true) 
            GET_getExistingMealRecord();
    }, []);

    useEffect(() => {
        let timerId: number;
        if (!showMealForm) {
            timerId = setTimeout(() => navigate(-1), 300) as unknown as number;
        } return () => clearTimeout(timerId);
    }, [showMealForm, navigate]);
    

    const handleSelectedTimeOfMealChange = (event: React.ChangeEvent<HTMLInputElement>): void => {

        setSelectedTimeOfMeal(event.target.value);
        const [year, month, day] = (date as string).split('-');
        const formatedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        const formatedDateWithTime = `${formatedDate}T${event.target.value}:00`;

        setMealData((prevState) => ({
            ...prevState,
            date: formatedDateWithTime,
        }));
    };

    const PUT_updateExistingMealRecord = async(event: React.FormEvent): Promise<void> => {
        event.preventDefault();

        try {
            let response = await axios.put(`${dietRoute}/${mealData.id}`, mealData);

            if (response.status === 201 || response.status === 200) {
                setMealData(initMealData);
                
                setTimeout(() => {
                    setShowMealForm(false);
                  }, 600);
            }
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response) 
                    setAxiosResponseMessage(e.response.data.message.join('. ') + '.');
            }
        }
    }
    
    const POST_createNewMealRecord = async(e: React.FormEvent): Promise<void> => {
        e.preventDefault();
        try {
          let response = await axios.post(dietRoute, mealData);
            
            if (response.status === 201 || response.status === 200) {
                setMealData(initMealData);
                
                setTimeout(() => {
                    setShowMealForm(false);
                  }, 600);
            }
        }
        catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response) 
                    setAxiosResponseMessage(e.response.data.message.join('. ') + '.');
            }
        }
    };

    const GET_getExistingMealRecord = async(): Promise<void> => {
        try {
            let response = await axios.get(`${dietRoute}/${id}`)

            if (response.status === 200 || response.status === 201) {
                setMealData(response.data);
                const mealTime = toLocalTime(response.data.date);
                setSelectedTimeOfMeal(mealTime)
            }
        }
        catch (error) {
            navigate('/diet')
        }
    };

    const DELETE_deleteSelectedMealRecord = async (): Promise<void> => {
        if (!mealData.id) return;
      
        try {
          const response = await axios.delete(`${dietRoute}/${mealData.id}`);
          if (response.status === 200) {
    
            setTimeout(() => {
                setShowMealForm(false);
              }, 600);
          }
        } catch (e) {
          console.error('Failed to delete the event', e);
          if (axios.isAxiosError(e)) {
            if (e.response) 
                setAxiosResponseMessage(e.response.data.message);
        }
        }
    };

    const handleMealDataChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {
        const { name, value } = e.target;
        setMealData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div className = {`${showMealForm ? 'dropdown-container open center'  : 'dropdown-container center'} ${formColor}`}>
            <form onSubmit={update? PUT_updateExistingMealRecord : POST_createNewMealRecord} className="form">

                <div className = 'title'>
                    {update? `Edit: ${mealData.name}` : 'Create Meal'}
                </div>
                <div className = 'title'>
                    { formatDate(date as string) }
                </div>

                {axiosResponseMessage && <div className = 'alert'>
                    {axiosResponseMessage}
                </div>}

                <div className = 'split-inputs'>
                    <select name="name" value={mealData.name} onChange={handleMealDataChange} required className="form-select">
                        {Object.entries(mealType).map(([key, value]) => {
                            return (
                                <option value = {value} key = {key}> {value} </option>
                            )
                        })}
                    </select>
                    <input
                        type="time"
                        value={selectedTimeOfMeal}
                        onChange={handleSelectedTimeOfMealChange}
                        required
                    />
                </div>
                <input
                    type = 'text'
                    name="food"
                    value={mealData.food}
                    onChange={handleMealDataChange}
                    placeholder=" Name Of The Meal"
                    required
                />
                <textarea
                    name="amount"
                    value={mealData.amount}
                    onChange={handleMealDataChange}
                    placeholder=" Amount, Ingredients or Description"
                    required
                    className="form-textarea" 
                />
                <div>
                    <label htmlFor="calories">Calories [kcal]:</label>
                    <input
                        name = "calories"
                        type= "number"
                        value = {mealData.calories}
                        onChange={handleMealDataChange}
                        placeholder="Calories">
                        
                    </input>
                </div>
                <button type="submit" className="global-button">{update? 'Update':'Submit'}</button>

                {update &&
                <button type="button" 
                        className="global-button delete" 
                        onClick={DELETE_deleteSelectedMealRecord}
                        disabled = {!update}>
                        Delete
                </button>
                }
                

            </form>
        </div>
    )
};

export default MealForm;