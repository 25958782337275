import axios from "axios";
import React, { useState } from "react";
import { loginRoute, registerRoute, resetPasswordRoute } from "../utils/routes";
import handleAxiosErrors from "../utils/functions";
import { Link,  useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import axiosInstance from '../redux/features/auth/axiosConfig'; 
import { setCredentials } from '../redux/features/auth/authSlice'; 
import { AppDispatch } from "../redux/store";
import { logoutUser } from "../redux/features/auth/authActions";

const Login: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [message, setMessage] = useState<string>('')
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const login = async() => {
        try {
          const response = await axiosInstance.post(loginRoute, { username, password });
          if (response.status === 200) {
            const { username, role } = response.data;
            dispatch(setCredentials({ username, role }));
            navigate('/');
          } else {
            throw new Error('Login failed');
          }
        } catch (error) {
          handleAxiosErrors(error, setMessage);
        }
      }
    return (
        <div className="login">
            <h1>Prijava</h1>
            {message && <h2>{message}</h2>}
            <div className="login-form">
                <form className = 'form' onSubmit={(e) => {e.preventDefault(); login()}}>
                    <input required type="text" placeholder="Username"  value = {username} onChange={(e) => setUsername(e.target.value)}/>
                    <input required type="password" placeholder="Password" value = {password} onChange={(e) => setPassword(e.target.value)}/>
                    <button type = 'submit' className="cta-button p05rem">Prijava</button>
                </form>
            </div>

            <div className = 'login-additional-buttons'>
                <h3>Še nisi registriran ?</h3>
                <Link className="cta-button p05rem blue" to = '/register'>Registracija</Link>
            </div>

            <div>
                <h3>Pozabljeno Geslo ?</h3>
                <Link className="cta-button p05rem brown" to = '/reset-password'>Ponastavi geslo</Link>
            </div>
        </div>
    );
};

interface userRegisterData {
    username: string;
    email: string;
    password1: string;
    password2: string;
    role: 'premium',
    company: string
}

export const LogOut: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const cancelLogout = () => {
        navigate(-1);
    }

    const handleLogout = () => {
        dispatch(logoutUser());
        navigate('/')
    };

    return (
        <div className = 'login'>
            <div className = 'login-form'>
                <div className = 'form'>
                    <h3>Ali Se resnično želiš odjaviti?</h3>
                    <button className = 'cta-button' onClick = {handleLogout}> Odjava </button>
                    <button className = 'cta-button nobg orangeborder' onClick = {cancelLogout}> Prekliči </button>
                </div>
            </div>
        </div>
    )
}

export const Register: React.FC = () => {

    const [showCompany, setShowCompany] = useState<boolean>(false);
    const [userData, setUserData] = useState<userRegisterData>({
        username: '',
        email: '',
        password1: '',
        password2: '',
        role: 'premium',
        company: ''
    });
    const [error, setError] = useState<string>('');
    const [registrationFinished, setRegistrationFinished] = useState<boolean>(false);

    const register = async() => {

        if (userData.password1 !== userData.password2) {
            setError('Passwords do not match!');
            return;
        }
        try {
            let response = await axios.post(registerRoute, {
                username: userData.username,
                email: userData.email,
                password: userData.password1,
                role: userData.role,
                company: userData.company
            })

            if (response.status >= 200 && response.status < 300) {
                console.log('Registration successful:', response.data);
                setRegistrationFinished(true);
                setError('');
                setError('Registration successful! Please check your inbox for confirmation link.');
            } else {
                setError('Unexpected response from server, please try again.');
            }
        }
        catch (error) {
            handleAxiosErrors(error, setError);
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <div className="login">
            <h1>Registracija</h1>
            
            <div className="login-form">
                {registrationFinished?
                <h4 style={{ color: 'green' }}>{error}</h4>
                :
                <>
                    {error && <h4 style={{ color: 'red' }}>{error}</h4>}
                    <form className = 'form' onSubmit={(e) => {e.preventDefault(); register()}}>
                        
                        <input required type="text" placeholder="Username" value = {userData.username} name = "username" onChange={handleInputChange}/>
                        <input required type="email" placeholder="Email" value = {userData.email} name = "email" onChange={handleInputChange}/>
                        <input required type="password" placeholder="Password" value = {userData.password1} name = "password1" onChange={handleInputChange}/>
                        <input required type="password" placeholder="Password" value = {userData.password2} name = "password2" onChange={handleInputChange}/>
                        <div style = {{display:'flex', flexDirection:'row', justifyContent:'space-between', width: '100%'}}>
                            <h4>Želiš Poln Dostop?</h4>
                            <input required type="checkbox" checked = {showCompany}  onChange={() => setShowCompany(!showCompany)} style = {{cursor:'pointer'}}/>
                        </div>
                        {showCompany && <input required type="text" placeholder="Company" value = {userData.company} name = "company" onChange={handleInputChange}/>}
                        {/* <input type="text" placeholder="Company" value = {userData.company} name = "company" onChange={handleInputChange}/> */}
                        <button type = 'submit' className="cta-button p05rem">Registracija</button>
                    </form>
                </>
                }
            </div>
            <div>
                <h3>Že registriran/a ?</h3>
                <Link className="cta-button p05rem blue" to = '/login'>Prijava</Link>
            </div>
        </div>
    );
};

export const ResetPassword: React.FC = () => {
    const [userEmail, setUserEmail] = useState<string>('');

    const resetPassword = async() => {
        try {
            let response = await axios.put(resetPasswordRoute, {email: userEmail});

            if (response && response.data.message) 
                console.log(response);
        } catch (error) {
            console.log(error);
        }
    }
    return(
        <div className="login">
            <h1>Ponastavi Geslo</h1>
            <div className="login-form">
                <form className = 'form' onSubmit={(e) => {e.preventDefault(); resetPassword()}}>
                    <input required type="text" placeholder="Email"  value = {userEmail} onChange={(e) => setUserEmail(e.target.value)}/>
                    <button type = 'submit' className="cta-button p05rem">Potrdi</button>
                </form>
            </div>
        </div>
    )
}

export const NewPassword: React.FC = () => {
    const [password1, setPassword1] = useState<string>('');
    const [password2, setPassword2] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    const navigate = useNavigate();

    let timeoutId: ReturnType<typeof setTimeout>;

    const closeAndRedirect = (): void => {
        const delayInMilliseconds = 3000;

        clearTimeout(timeoutId);
    
        timeoutId = setTimeout(() => {
            navigate('/')
        }, delayInMilliseconds);
    }

    const resetPassword = async() => {
        if (password1 !== password2) {
            setMessage('Passwords do not match!');
            return;
        }
        try {
            let response = await axios.post(resetPasswordRoute, {password: password1}, {withCredentials: true});
            if (response.status >= 200 && response.status < 300) {
                setMessage('');
                setMessage('Password Updated!');
                setIsUpdated(true);
                closeAndRedirect()
            } else {
                setIsUpdated(false);
                setMessage('Unexpected response from server, please try again.');
            }
        }
        catch (error) {
            setIsUpdated(false);
            handleAxiosErrors(error, setMessage);
        }
    }

    return (
        <div className="login">
            <h1>Ponastavi Geslo</h1>
            { message && <h2> {message} </h2> }
            <div className="login-form">

                {!isUpdated &&
                    <form className = 'form' onSubmit={(e) => {e.preventDefault(); resetPassword()}}>
                        <input  required 
                                type="text" 
                                placeholder="New Password"  
                                value = {password1} 
                                onChange={(e) => setPassword1(e.target.value)}/>
                        <input  required 
                                type="text" 
                                placeholder="Repeat Password"  
                                value = {password2} 
                                onChange={(e) => setPassword2(e.target.value)}/>
                        <button type = 'submit' className="cta-button p05rem">Potrdi</button>
                    </form>
                }
            </div>
        </div>
    )
}

export default Login;