import {Suspense, useEffect, useRef, useState, useMemo, useCallback} from 'react';
import { Canvas, useFrame, useLoader} from '@react-three/fiber';
import { MeshReflectorMaterial, OrbitControls, PerspectiveCamera, Html, } from '@react-three/drei';
import { Mesh, RepeatWrapping, TextureLoader, AnimationMixer,} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { imagesRoute, modelsRoute } from '../utils/routes';
import { useNavigate } from 'react-router-dom';

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/');

function Ratatouille({ switchArray }: any) {
    const [lightsState, setLightsState] = useState([false, false, false]);

    const spotlightRef = useRef<any>();
    const targetRef = useRef<any>();

    const roughness = useLoader(TextureLoader, `${imagesRoute}?fileName=terrain-roughness_r.jpg`);
    const normal = useLoader(TextureLoader, `${imagesRoute}?fileName=terrain-normal_r.jpg`);

    useEffect(() => {
        if (spotlightRef.current && targetRef.current) {
            targetRef.current.updateMatrixWorld(true);
            spotlightRef.current.target = targetRef.current; 
            spotlightRef.current.lookAt(targetRef.current.position);
        }
    }, [spotlightRef, targetRef]);

    useEffect(() => {
        const timers = [
            setTimeout(() => setLightsState(prev => [true, ...prev.slice(1)]), 1000),
            setTimeout(() => setLightsState(prev => [prev[0], true, prev[2]]), 2000),
            setTimeout(() => setLightsState(prev => [prev[0], prev[1], true]), 3000),
        ];
        return () => timers.forEach(clearTimeout);
    }, []);

    const lightsConfig:any = useMemo(() => ([
        { color: lightsState[0] ? [0.14, 0.5, 1] : [0,0,0], position: [5,5,0], shadow: false },
        { color: lightsState[1] ? [1, 0.25, 0.7] : [0,0,0], position: [-5,5,0], shadow: true },
        { color: lightsState[2] ? [0.14, 0.5, 1] : [0,0,0], position: [5,6,10], intensity: 300, angle: 12, penumbra: 0.5, shadow: false },
    ]), [lightsState]);

    return (
        <>
            <OrbitControls target={[0, 0.35, 0]} maxPolarAngle={1.45} />
            <PerspectiveCamera makeDefault fov={40} position={[-9, 10, 22]} />
            <color args={[0, 0, 0]} attach="background"/>
            
            {lightsConfig.map((light: any, idx: any) => (
                <spotLight
                    key={idx}
                    color={light.color}
                    intensity={light.intensity || 200}
                    angle={light.angle || 8.6}
                    penumbra={light.penumbra || 0.5}
                    position={light.position}
                    castShadow = {light.shadow} 
                    shadow-bias={-0.0001}
                />
            ))}

            <Ground roughness={roughness} normal={normal} />
            <Mouse />
            <Piggy_bank switchArray={switchArray}/>
            <Chair/>
            <Mail camera={targetRef} switchArray={switchArray} />
            <LinkedIn />
            <Projector />
            <Maroltova roughness={roughness} normal={normal} />
        </>
    );
}
function Ground({ roughness, normal }: any) {

    [normal, roughness].forEach((t) => {
        t.wrapS = RepeatWrapping;
        t.wrapT = RepeatWrapping;
        t.repeat.set(3, 3);
    });
    normal.colorSpace = 'srgb-linear';

    return (
        <mesh rotation-x={-Math.PI / 2} castShadow= {false} receiveShadow>
            <planeGeometry args={[60, 60]} />
            <MeshReflectorMaterial
                map={roughness}
                envMapIntensity={0}
                color={'#646464'}
                mixStrength={80}
                resolution={512} 
                mirror={0}
                depthScale={0.01}
                minDepthThreshold={0.9}
                maxDepthThreshold={1}
                depthToBlurRatioBias={0.25}
            />
        </mesh>
    );
}
function Maroltova({ roughness, normal }: any) {
    
    const gltf = useLoader(GLTFLoader, `${modelsRoute}?fileName=maroltova_compressed.glb`, (loader) => {
        loader.setDRACOLoader(dracoLoader);
    });

    const optimizedScene = useMemo(() => {
        const sceneCopy = gltf.scene.clone();

        sceneCopy.traverse((object) => {
            if (object instanceof Mesh) {
                object.castShadow = false; 
                object.receiveShadow = false;
                
                object.material.map = roughness;
            }
        });

        sceneCopy.scale.set(50, 50, 50);
        sceneCopy.position.set(0, -0.59, -10);

        return sceneCopy;
    }, [gltf, roughness, normal]);

    return <primitive object={optimizedScene} />;
}
function Mail({ camera }: any) {
    const navigate = useNavigate();

    const gltf = useLoader(GLTFLoader, `${modelsRoute}?fileName=mail_compressed.glb`, (loader) => {
        loader.setDRACOLoader(dracoLoader);
    });
    

    const optimizedScene = useMemo(() => {
        const sceneCopy = gltf.scene.clone();

        sceneCopy.scale.set(0.5, 0.5, 0.5);
        sceneCopy.position.set(9, 0, 0);
        sceneCopy.rotation.set(0, 5, 0);

        return sceneCopy;
    }, [gltf]);

    const handleClick = () => {
        navigate('/contact');
    }

    return (
        <primitive object={optimizedScene}
                   ref={camera}
                   onClick={handleClick} />
    );
}
function Piggy_bank({ camera }: any) {
    const gltf = useLoader(GLTFLoader, `${modelsRoute}?fileName=piggy_bank.glb`, (loader) => {
        loader.setDRACOLoader(dracoLoader);
    });

    const optimizedScene = useMemo(() => {
        const sceneCopy = gltf.scene.clone();

        sceneCopy.scale.set(2, 2, 2);
        sceneCopy.position.set(-7, 0, -13);
        sceneCopy.rotation.set(0, 1.1, 0);

        return sceneCopy;
    }, [gltf]);



    return (
        <primitive object={optimizedScene}
                   ref={camera}/>
    );
}
function LinkedIn() {
    const gltf = useLoader(GLTFLoader, `${modelsRoute}?fileName=linkedin_compressed.glb`, (loader) => {
        loader.setDRACOLoader(dracoLoader);
    });

    const optimizedScene = useMemo(() => {
        const sceneCopy = gltf.scene.clone();

        sceneCopy.scale.set(0.5, 0.5, 0.5);
        sceneCopy.position.set(9, 1.9, -0.3);
        sceneCopy.rotation.set(0, 4.6, 0);

        return sceneCopy;
    }, [gltf]);

    const handleClick = useCallback((e: any) => {
        e.stopPropagation();
        window.open('https://www.linkedin.com/in/jernej-penko');
    }, []);

    return (
        <primitive object={optimizedScene}
                   onClick={handleClick} />
    );
}
function Chair() {
    const gltf = useLoader(GLTFLoader, `${modelsRoute}?fileName=beach_chair.glb`, (loader) => {
        loader.setDRACOLoader(dracoLoader);
    });

    const optimizedScene = useMemo(() => {
        const sceneCopy = gltf.scene.clone();
        
        sceneCopy.scale.set(14, 16, 9);
        sceneCopy.position.set(-0.25, 0, 0.06);
        sceneCopy.rotation.set(0, 3.4, 0);

        return sceneCopy;
    }, [gltf]);

    return (
        <primitive object={optimizedScene} />
    );
}
function Projector() {
    const [loadVideo, setLoadVideo] = useState(false);

    const gltf = useLoader(GLTFLoader, `${modelsRoute}?fileName=youtube.glb`, (loader) => {
        loader.setDRACOLoader(dracoLoader);
    });

    const optimizedScene = useMemo(() => {
        const sceneCopy = gltf.scene.clone();

        sceneCopy.scale.set(4, 4, 4);
        sceneCopy.position.set(-9, 0, 2);
        sceneCopy.rotation.set(0, 0.2, 0);

        return sceneCopy;
    }, [gltf]);

    const handleThumbnailClick = () => {
        setLoadVideo(true);
    };

    return (
        <>
            <primitive object={optimizedScene} />
            <Html position={[-8.7, 4.4, 1.95]} rotation={[0, 1.77, 0]} transform style={{ opacity: '1' }} scale={[1.06, 1.13, 1.1]}>
                {loadVideo ? 
                <>
                    <iframe 
                        src="https://www.youtube.com/embed/Ih6jcKd7VwU?" 
                        title="Ratatouille Ego&#39;s Review" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowFullScreen>
                    </iframe></>
                    :
                    <>
                    
                    <img
                        src={`${imagesRoute}?fileName=ratatouille_thumbnail.jpg`}
                        onClick={handleThumbnailClick}
                        alt="Video thumbnail"
                        height='160px'
                        width="255px"
                    /> </>
                }
            </Html>
        </>
    );
}


function Mouse() {
    const gltf = useLoader(GLTFLoader, `${modelsRoute}?fileName=rat_compressed.glb`, (loader) => {
        loader.setDRACOLoader(dracoLoader);
    });

    const mixerRef = useRef<any>(null);

    useEffect(() => {
        return () => mixerRef.current?.stopAllAction();
    }, []);

    useEffect(() => {
        if (gltf.animations && gltf.animations.length > 0) {
            if (!mixerRef.current) {
                mixerRef.current = new AnimationMixer(gltf.scene);
            }
            
            const action = mixerRef.current.clipAction(gltf.animations[0]);
            action.play();
        }

        gltf.scene.traverse((object) => {
            if (object instanceof Mesh) {
                object.castShadow = true;
                object.receiveShadow = true;
            }
        });

        gltf.scene.position.set(0, 0.3, 0);
        gltf.scene.rotation.set(-0.5, 11.2, -0.5);
    }, [gltf]);

    useFrame((_, delta) => mixerRef.current?.update(delta));

    return <primitive object={gltf.scene} />;
}

function R3D ({switchArray}: any) {
    return (
    <div className = 'IFCviewer-container whiteborder'>
        <Suspense fallback = {null}>
            <Canvas shadows>
                <Ratatouille switchArray = {switchArray}/>
            </Canvas>
        </Suspense>
    </div>
    )
};

export default R3D;