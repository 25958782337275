import { inProduction } from "./cfg";


export const backendServer: string = inProduction? 'https://cometcorner.com/api/': /* 'http://10.16.38.201:3002/api/'; */ 'http://localhost:3002/api/'

export const eventRoute: string = backendServer + 'event';
export const coverLetterRoute: string = backendServer + 'cover-letter';

export const filesRoute: string = backendServer + 'files/'

export const imagesRoute: string = filesRoute + 'images/'
export const pdfsRoute: string = filesRoute + 'pdfs/'
export const modelsRoute: string = filesRoute + 'models/'

export const resumeRoute: string = filesRoute + 'resume';
export const moonGlbRoute: string = filesRoute + 'moon-glb';

export const dietRoute: string = backendServer + 'diet';

export const gymRoute: string = backendServer + 'gym/';
export const workoutTypeRoute: string = gymRoute + 'workout-type';
export const exerciseLogRoute: string = gymRoute + 'exercise-log';

export const eurojackpotRoute: string = backendServer + 'eurojackpot';
export const eurojackpotPredictionProcessRoute: string = eurojackpotRoute + '/prediction-process';

export const authRoute: string = backendServer + 'auth/';
export const loginRoute: string = authRoute + 'login';
export const registerRoute: string = authRoute + 'register'
export const logoutRoute: string = authRoute + 'logout'
export const checkSessionRoute: string = authRoute + 'session';
export const resetPasswordRoute: string = authRoute + 'reset-password'
export const contactRoute: string = backendServer + 'contact'

