import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LineChart, Line, Tooltip, ResponsiveContainer, YAxis, CartesianGrid } from 'recharts';
import { exerciseLogRoute } from "../../../utils/routes";
import { formatDate, getFormattedDate } from "../../../utils/functions";
import { WorkoutInfo } from "../fitness.interfaces";


interface exerciseHistoryLogInterface {
    id: number;
    oneRM: number;
    date: string;
}

const ExerciseDashBoard: React.FC = () => {
    const { exercise } = useParams();
    const navigate = useNavigate();

    const initWorkoutLog: WorkoutInfo = {
        oneRM: 0,
        reps: 0,
        weight: 0,
        date: getFormattedDate(),
        name: exercise as string
    };
    
    const [currentExerciseLog, setCurrentExerciseLog] = useState<WorkoutInfo>(initWorkoutLog);
    const [historicalExerciseLogs, setHistoricalExerciseLogs] = useState<exerciseHistoryLogInterface[]>([]);

    useEffect(() => {
      GET_exerciseHistoryLogs();
      GET_latestExerciseLog(false);
    }, [])

    const sortByDate = (data: exerciseHistoryLogInterface[]): exerciseHistoryLogInterface[] => {
        return data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    };

    const GET_latestExerciseLog = async(useCurrentDate: boolean): Promise<void> => {
      try {
          let response = await axios.get(`${exerciseLogRoute}/${exercise}/latest`)

          if (response.status === 200 || response.status === 201) {
            if (Object.keys(response.data).length > 0){
              let exerciseLogPreset: WorkoutInfo = {
                  ...response.data,
                  date: useCurrentDate? getFormattedDate() : response.data.date
              }
              setCurrentExerciseLog(exerciseLogPreset)
            }
          }
      }
      catch(error) {
          console.log(error)
      }
    };

    const GET_selectedExerciseLog = async(id: number): Promise<void> => {
        try {
            let response = await axios.get(`${exerciseLogRoute}/exercise/${id}`);
            if (response.status === 201 || response.status === 200) {

                let exerciseLog = response.data;
                if (exerciseLog.date)
                  exerciseLog.date = exerciseLog.date.split('T')[0]
                  setCurrentExerciseLog(exerciseLog);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const GET_exerciseHistoryLogs = async(): Promise<void> => {
        try {
            let response = await axios.get(`${exerciseLogRoute}/${exercise}`);
            if (response.status === 201 || response.status === 200) {
                let sortedHistoricalExerciseLogs = sortByDate(response.data);
                
                if (sortedHistoricalExerciseLogs.length > 0) {
                  setHistoricalExerciseLogs(sortedHistoricalExerciseLogs);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const CustomGraphTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {

          const date = payload[0].payload.date; 
          const formattedDate = formatDate(date);
      
          return (
            <div className="custom-tooltip">
              <p>{formattedDate}</p>
              <p>1RM: {payload[0].value} kg</p>
            </div>
          );
        }
      
        return null;
    };
      
    const CustomGraphActiveDot = (props: any) => {
      const { cx, cy, payload } = props;
    
      const getSelectedExerciseLog = async() => {
        await GET_selectedExerciseLog(payload.id);
      };
    
      return (
        <circle cx={cx} cy={cy} r={10} fill="#8884d8" stroke="none" onClick={getSelectedExerciseLog} />
      );
    };

    const navigateToEditLogForm = (): void => {
      if (historicalExerciseLogs.length > 0)
        navigate(`edit/${currentExerciseLog.id}`)
    }

    const navigateToNewLogForm = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      navigate('new-log');
    };

    const graphMargins = {
      top: 30,
      right: 20,
      left: -35,
      bottom: 5,
    }

    return (
        <div className = 'exercise-dashboard-container'>
          <section className = 'narrowTitle'>
          
          <div className = 'txt-sdw'>
            {exercise}
          </div>
        </section>
        <div className = 'linear-bg' style = {{height:'100vh'}}>
          <div className = 'graph-section'>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                  width={500}
                  height={300}
                  data={historicalExerciseLogs}
                  margin={graphMargins}
              >
                <YAxis tick={{ dx: 35, dy: -10 }} tickLine={false} axisLine={false}/>
                <CartesianGrid stroke="rgb(255,255,255,1)" strokeDasharray="3 3" vertical={false} />
                <Tooltip content={<CustomGraphTooltip/>}/>
                <Line type="monotone" dataKey="oneRM" stroke="#8884d8" activeDot={<CustomGraphActiveDot/>} />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className = 'data-section'>
            <div className = 'data' onClick={navigateToEditLogForm}>

              <div className = 'date'>
                {historicalExerciseLogs.length > 0?
                  formatDate(currentExerciseLog.date)
                    :
                  'No Existing Records Yet'
                }
              </div>

              <ExerciseLogInformation title = "1RM" value={currentExerciseLog.oneRM} units="kg"/>
              <ExerciseLogInformation title = "Weight" value={currentExerciseLog.weight} units="kg"/>
              <ExerciseLogInformation title = "Reps" value={currentExerciseLog.reps} units="x"/>
            </div>
          </div>
          <button onClick={navigateToNewLogForm} className="new-button">+</button>
          </div>
         
        </div>
      );
};

interface ExerciseLogInformationInterface {
  title: string,
  value: number,
  units: string
}

const ExerciseLogInformation: React.FC<ExerciseLogInformationInterface> = ({title, value, units}) => {
  return (
    <div className = 'data-split'>
        <div>{title}: </div>
        <div>{value}{units}</div>
    </div>
  )
}


export default ExerciseDashBoard;