

const Profile: React.FC = () => {
    return (
        <div className = 'home'>
            <section id = "home" className = 'home-welcome-text'>
                <div className = 'txt-sdw'>
                    <span className = 'orange'>Profil</span>
                    <h2>Coming soon...</h2>
                </div>
            </section>
        </div>
    )
};

export default Profile;