import axios from "axios";
import { useCallback, useState } from "react";
import { contactRoute, eventRoute } from "../utils/routes";
import { FaLink } from "react-icons/fa";
import handleAxiosErrors from "../utils/functions";


const Contact: React.FC = () => {
    return (
        <div className = 'home'>
            <section className = 'narrowTitle'>
                <div className = 'txt-sdw'>
                    Kontakt
                </div>
            </section>
            <div className = 'linear-bg'>
                <section id = "home" className = 'content'>
                    <div className = 'text-section'>
                        <h2>E-Mail</h2>
                        <p>Penko.jernej.98@gmail.com</p>

                        <h2><span className = 'orange'>Linked</span>In</h2>
                        <a href="https://www.linkedin.com/in/jernej-penko/" target="_blank" rel="noopener noreferrer">
                            <FaLink /> LinkedIn
                        </a>
                    </div>
                    <SendEmail/>
                </section>
            </div>
            
        </div>
    )
};

export default Contact;


interface email {
    from: string,
    subject: string,
    text: string
}
export function SendEmail() {
    const INITIAL_EMAIL_DATA: email = {
        from: '',
        subject: '',
        text: ''
    }
    const [message, setMessage] = useState<string>('');
    const [email, setEmail] = useState<email>(INITIAL_EMAIL_DATA);
    const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

    const sendEmail = useCallback(async (e: any) => {

        e.preventDefault();
        try {
            const response = await axios.post(contactRoute, email);

            if (response.status == 200) {
                setIsEmailSent(true);
                setMessage(response.data.message);
            }

        } catch (error) {
            handleAxiosErrors(error, setMessage);
        }
    }, [email]);

    const handleInputChange = useCallback((e: any) => {
        const { name, value } = e.target;
        setEmail(prev => ({ ...prev, [name]: value }));
    }, []);

    return (
    <>
        {isEmailSent?
            <div className = 'form'>
                <h1>{message}</h1>
            </div>
        :
            <form className='form'>
                From:
                <input  name="from" placeholder="Enter Your E-mail"
                        type = 'text'
                        onChange={handleInputChange}
                        value={email.from}
                        maxLength={50} />
                Subject:
                <input  name="subject" 
                        type = 'text'
                        onChange={handleInputChange}
                        value={email.subject}
                        maxLength={40} />
                    Text:
                    <textarea   name="text" 
                                onChange={handleInputChange}
                                value={email.text}
                                maxLength={500} />
                <button className='cta-button' onClick={sendEmail}>
                    Send
                </button>
                {message && <h1>{message}</h1>}
            </form>
            }
    </>
    )
}
