import React from 'react';
import { GiCometSpark } from 'react-icons/gi';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <section className="footer-section">
        <Link onClick={() => window.scrollTo(0, 0)} to={'/'} id="scrollToTop" className='dummy-header-brand txt-sdw'>C<div className = 'orange'><GiCometSpark/></div>met Corner</Link>
          <ul>
            <li>
              <Link className = 'link' 
                    to='/' 
                    onClick={() => window.scrollTo(0, 0)}>
                  Domov
              </Link>
            </li>

            <li>
              <Link className = 'link' 
                    to='/experience' 
                    onClick={() => window.scrollTo(0, 0)}>
                  Experience
              </Link>
            </li>

            <li>
              <Link className = 'link' 
                    to='/blog' 
                    onClick={() => window.scrollTo(0, 0)}>
                  Blog
              </Link>
            </li>

            <li>
              <Link className = 'link' 
                    to='/contact' 
                    onClick={() => window.scrollTo(0, 0)}>
                  Kontakt
              </Link>
            </li>
          </ul>
        </section>
        <section className="footer-section">
          <h3>Follow Us</h3>
          <ul>
            <li><a href="https://twitter.com">Twitter</a></li>
            <li><a href="https://facebook.com">Facebook</a></li>
            <li><a href="https://instagram.com">Instagram</a></li>
          </ul>
        </section>
{/*         <section className="footer-section newsletter">
          <h3>Newsletter</h3>
          <form>
            <input type="email" placeholder="Your email address" />
            <button type="submit">Subscribe</button>
          </form>
        </section> */}
      </div>
      <div className="footer-bottom">
        © {new Date().getFullYear()} Penko Jernej. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;
