import { useEffect, useState } from 'react';
import axios from 'axios';
import { dietRoute } from '../../../utils/routes';
import { useNavigate } from 'react-router-dom';
import { getFormattedDate } from '../../../utils/functions';

interface DietRecordSummary {
  date: string;
  names: string[];
  calories: number;
}
interface DayRecord {
  day: number;
  record?: DietRecordSummary;
  formattedDate: string;
}

const DayList: React.FC = () => {

  const navigate = useNavigate();

  const [dietRecords, setDietRecords] = useState<DayRecord[]>([]);
  const [caloriesAverage, setCaloriesAverage] = useState<number>(0);

  const [selectedDate, setSelectedDate] = useState<string>(() => {
    const now = new Date();
    return `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}`;
  });

  const daysInMonth = (month: number, year: number) => new Date(year, month, 0).getDate();
  const [year, month] = selectedDate.split('-').map(Number);

  useEffect(() => {
    fetchDietRecords();
  }, [selectedDate]);
  
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedDate(e.target.value);
  };

  const navigateToSelectedDateRecords = (date: string): void => {
    navigate(`by-date/${date}`)
  }

  const fetchDietRecords = async (): Promise<void> => {
    const [year, month] = selectedDate.split('-').map(Number);
    try {
      const response = await axios.get(`${dietRoute}/days`, {
        params: {
          month,
          year
        },
      });
      const formattedDietRecords = generateDailyDietBrief(response.data);
      setDietRecords(formattedDietRecords);
      calculateAverageCalories(response.data);
    } catch (error) {
      console.error('Error fetching diet records:', error);
    }
  };
  const calculateAverageCalories = (summaries: DietRecordSummary[]): void => {
    if (summaries.length === 0) return;
  
    const totalCalories = summaries.reduce((sum, record) => sum + record.calories, 0);
    const averageCalories = Math.ceil(totalCalories / summaries.length);
    setCaloriesAverage(averageCalories)
  };
  

  const generateDailyDietBrief = (unformattedDietRecords: DietRecordSummary[]): DayRecord[] => {
    return Array.from({ length: daysInMonth(month, year) }, (_, dayIndex) => {
      const day = dayIndex + 1;
      const matchedDietRecord = unformattedDietRecords.find(record => new Date(record.date).getDate() === day);
      const isoFormattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      return { day, record: matchedDietRecord, formattedDate: isoFormattedDate };
    });
  };


  const navigateToNewMealForm = (): void => {
    navigate(`by-date/${getFormattedDate()}/new`)
  }
  const navigateToAiDietitian = ():void => {
    navigate('/apps/diet-tracker/ai');
  }

  return (
    <div className = 'daylist-container linear-bg'>
        <div className = 'daylist-title'>
            <input
              type="month"
              value={selectedDate}
              onChange={handleDateChange}
            />
          <div className = 'calories-sum'>Daily Calories Average: {caloriesAverage} kcal</div>
        </div>

        
        <ul  className='listRemoveMP'>
          {dietRecords.map(({ day, record, formattedDate }) => (
            <DayRecord
              key={day}
              day={day}
              record={record}
              formattedDate={formattedDate}
              navigateToSelectedDateRecords={navigateToSelectedDateRecords}
            />
          ))}
        </ul>
        <div style = {{display:'flex', flexDirection:'row', width:'100vw', position:'sticky', bottom: '0', justifyContent:'space-around'}}>
          <button onClick={navigateToNewMealForm} className="new-button">+</button>
          <button onClick={navigateToAiDietitian} className = "ai-button">AI</button>
        </div>
    </div>
  );
};

interface DayRecordProps {
  day: number; 
  record?: DietRecordSummary;
  formattedDate: string;
  navigateToSelectedDateRecords: (date: string) => void;
}

const DayRecord: React.FC<DayRecordProps> = ({ day, record, formattedDate, navigateToSelectedDateRecords }) => {
  return (
    <li className='listItem p5' onClick={() => navigateToSelectedDateRecords(formattedDate)}>
      <h3>{day}.</h3>
      {record && <p>{record.names.join(' - ')}</p>}
      {record && <p>{record.calories} kcal</p>}
    </li>
  );
};

export default DayList;
