import React, { Suspense } from "react";
import { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
const IfcViewer = React.lazy(() => import('../custom/_ifc.repo'));

export const LazyLoadComponent = ({ children, fallbackImage, fallbackClassName }: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const domRef: any = useRef();
  
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !isVisible) {
          setIsVisible(true);
          observer.disconnect();
        }
      });
    }, { rootMargin: '100%' });

    const { current } = domRef;
    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.disconnect();
      }
    };
  }, [isVisible]);

  return (
    <div ref={domRef} style = {{height: '100%'}} className = 'fade-in'>
      {isVisible ? (
        <Suspense fallback={<div className = {fallbackClassName}></div>}>
          {children}
        </Suspense>
      ) : null} 
    </div>
  );
};



export const HomePage: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
      if (location.hash) {
          const id = location.hash.replace("#", "");
          const element = document.getElementById(id);
          if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
          }
      }
  }, [location]);

    return (
      <div className='home'>
        
        <section id = "home" className = 'home-welcome-text'>
          <div className = 'txt-sdw'><span className = 'orange'>Spletne </span>rešitve
          </div>
        </section>
      </div>
    );
  }

export default HomePage;