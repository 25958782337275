import React, { useEffect, useRef } from 'react';
import * as paper from 'paper';

const TadpoleBackground: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const tadpoles: Tadpole[] = [];

  useEffect(() => {
    if (canvasRef.current) {
      paper.setup(canvasRef.current);
      const tool = new paper.Tool();

      paper.view.onFrame = () => {
        tadpoles.forEach(tadpole => tadpole.move());
      };

      for (let tadpole = 0; tadpole < 3; tadpole++) {
        const position = paper.view.center
        tadpoles.push(new Tadpole(position,tadpole,3));
      }

      tool.onMouseMove = (event: paper.ToolEvent) => {
        tadpoles.forEach(tadpole => tadpole.pointTowards(event.point));
      };
    }
  }, []);

  return  <canvas ref={canvasRef} className="tadpole-background" />
};




class Tadpole {
    static lastMousePosition = new paper.Point(0,0);
    head: paper.Path.Circle;
    vector: paper.Point;
    tail: paper.Path; // Represents the tail
  
    constructor(position: paper.Point, index: number, totalEntities: number) {
      this.head = new paper.Path.Circle({
        center: position,
        radius: 0,
        fillColor: 'white',
      });
      const totalAngle = 2 * Math.PI;
        
      // Find the angle increment for each entity
      const angleIncrement = totalAngle / totalEntities;
      
      // Calculate the angle for the current entity based on its index
      const angle = angleIncrement * index;
      
      // Assuming a fixed speed for simplicity
      const speed = 5; // Adjust the speed as necessary
      
      // Convert the angle to a directional vector
      this.vector = new paper.Point(Math.cos(angle) * speed, Math.sin(angle) * speed);
      
      this.vector.length =  5;
  
      this.tail = new paper.Path({
        strokeColor: /* 'rgb(210,255,255,0.5)' *//* 'rgb(255, 165, 0, 0.5)' */this.getRandomColor(),
        strokeWidth: Math.random() * 0.5,
      });
      for (let i = 0; i < 300; i++) { // Tail made of 10 points
        this.tail.add(position);
      }
    }
    
    
    // Usage in your entity's constructor or relevant method

    getRandomColor() {
      // Define the colors to choose from
      const colors = ['rgb(210,255,255,0.7)', 'rgb(255, 165, 0, 0.7)'];
      // Generate a random index: 0 or 1
      const randomIndex = Math.floor(Math.random() * colors.length);
      // Return the color at the random index
      return colors[randomIndex];
    }
  
    move() {
      // Move head
      this.head.position = this.head.position.add(this.vector);
  
      // Move each segment of the tail towards the head
      for (let i = 0; i < this.tail.segments.length - 1; i++) {
        const segment = this.tail.segments[i];
        const nextSegment = this.tail.segments[i + 1];
        segment.point = segment.point.add(nextSegment.point.subtract(segment.point).multiply(0.2));
      }
      this.tail.segments[this.tail.segments.length - 1].point = this.head.position;
  
      // Reverse direction if hitting the bounds
      if (
        this.head.bounds.left < 0 || 
        this.head.bounds.right > paper.view.bounds.width ||
        this.head.bounds.top < 0 || 
        this.head.bounds.bottom > paper.view.bounds.height
      ) {
        this.vector.angle += 72; // Simply reverse the angle
      }
    }
  
    // Method to point tadpole towards the mouse
    pointTowards(position: paper.Point) {
      const direction = position.subtract(this.head.position);
      this.vector = direction.normalize(Math.random() * 10);
    }
  }
export default TadpoleBackground;
