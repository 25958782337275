import axios from "axios";

const handleAxiosErrors = (error: any, setError: React.Dispatch<React.SetStateAction<string>>) => {
    if (axios.isAxiosError(error)) {
        if (error.response) {
            console.error('Error response data:', error.response.data);
            console.error('Error response status:', error.response.status);
            switch (error.response.status) {
                case 400:
                    setError('Invalid request. Please check your data.');
                    break;
                case 401:
                    setError(error.response.data.message);
                    break;
                case 403:
                    setError('Access denied.');
                    break;
                case 404:
                    setError('The requested resource was not found.');
                    break;
                case 500:
                    setError('Internal server error. Please try again later.');
                    break;
                default:
                    setError('An unexpected error occurred. Please try again.');
            }
        } else {
            setError('Network error. Please check your connection and try again.');
        }
    } else {
        setError('An unexpected error occurred. Please try again.');
        console.error('Non-Axios error:', error);
    }
};
export const toLocalTime = (isoDate: string): string => {
    const date = new Date(isoDate);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  
  export const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = `${today.getMonth() + 1}`.padStart(2, '0'); // Months are 0-based, add 1 to make it 1-based
    const day = `${today.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  export const formatDate = (dateString: string): String => {
    const parts = dateString.split('-');

    const year = parts[0];
    const month = parts[1].padStart(2, '0');
    const day = parts[2].padStart(2, '0');

    const date = new Date(`${year}-${month}-${day}`);

    return date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
};
export default handleAxiosErrors;
