import { Link } from "react-router-dom";
import IfcViewer from "../../../custom/_ifc.repo"
import { LazyLoadComponent } from "../../_home"
import { FaLink } from "react-icons/fa";
import R3D from "../../../Components/_three.js";


const JSLibs: React.FC = () => {
    return ( 
        <div className='home'>
        
{/*         <section id = "home" className = 'home-welcome-text'>
          
          <div className = 'txt-sdw'><span className = 'orange'>Spletne </span>rešitve
          </div>
        </section> */}
          <section className = 'narrowTitle'>
          
          <div className = 'txt-sdw'>
            <span className = 'orange'>JS</span> Knjižnice
          </div>
        </section>
        <div className = 'linear-bg'>
            <section className = 'content'>
            <div className = 'text-section'>
                    <h3>That<span className = 'orange'>open</span></h3>
                    <p>
                        Knjižnica "That Open" omogoča razvoj lastne programske opreme za upravljanje z gradbenimi informacijskimi modeli (BIM) z uporabo JavaScript knjižnic, ki so brezplačne in odprtokodne. Uporabljajo se za izdelavo BIM aplikacij za različne platforme: splet (HTML, CSS, React), strežnike (Node.js), namizne aplikacije (Electron) in mobilne naprave (React Native).
                    </p>
                    <p>
                        Knjižnica ponuja orodja za uvoz, prikaz, navigacijo, urejanje in izvoz IFC modelov ter drugih 3D formatov. Vključuje tudi funkcije za modeliranje, shranjevanje, obdelavo in distribucijo BIM modelov, dokumentacijo in integracijo z različnimi sistemi, kot so SharePoint, PowerBI in Google Drive.
                    </p>
                    <a href="https://docs.thatopen.com/intro" target="_blank" rel="noopener noreferrer">
                        <FaLink /> Docs.thatopen.com
                    </a>
            </div>
            <div className = 'text-section hvr-scl'>
                <LazyLoadComponent fallbackClassName='IFCviewer-container'>
                    <IfcViewer />
                </LazyLoadComponent>
            </div>
            </section>
            <section className = 'content reverse'>
            <div className = 'text-section'>
                    <h3>Three<span className = 'orange'>.js</span></h3>
                    <p>
                        Knjižnica "Three.js" razvijalcem omogoča oblikovanje naprednih 3D aplikacij z uporabo sodobnih tehnologij in odprtokodnih JavaScript orodij. Ta močna, a vseeno lahka knjižnica je idealna za izdelavo dinamičnih 3D scen na spletu, v namiznih in mobilnih okoljih, združljiva pa je tudi z HTML, CSS in Reactom.
                    </p>
                    <p>
                        Z orodji za uvoz, prikaz, urejanje in izvoz različnih 3D modelov, Three.js preoblikuje interaktivno grafično uporabniško izkušnjo. Knjižnica podpira realnočasovno renderiranje, animacijo in simulacijo fizikalnih interakcij, kar omogoča ustvarjanje živahnih in vizualno privlačnih aplikacij, ki so tehnično dovršene in estetsko privlačne.
                    </p>
                    <a href="https://threejs.org/docs/" target="_blank" rel="noopener noreferrer">
                        <FaLink /> threejs.org/docs
                    </a>
            </div>
            <div className = 'text-section hvr-scl'>
                <LazyLoadComponent fallbackClassName='IFCviewer-container'>
                    <R3D />
                </LazyLoadComponent>
            </div>
            </section>
        </div>
      </div>
    )
};

export default JSLibs;